/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Engenharia</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Planejamentos'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen005.svg'>
        {/* <SidebarMenuItem
          icon='/media/icons/duotune/general/gen049.svg'
          to='servicos'
          title='Serviços'
          hasBullet={false}
        ></SidebarMenuItem> */}
        <SidebarMenuItem
          to='/pedidos'
          icon='/media/icons/duotune/files/fil024.svg'
          title='Pedidos'
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem icon='/media/icons/duotune/finance/fin010.svg'
        to='orcamentos'
        title='Orçamentos'
        hasBullet={false} />
      </SidebarMenuItemWithSub>
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Vendas</span>
        </div>
      </div>
      <SidebarMenuItemWithSub to='/crafted/pages' title='Dashboard' fontIcon='bi-archive' icon='/media/icons/duotune/abstract/abs027.svg'>
        <SidebarMenuItem icon='/media/icons/duotune/graphs/gra012.svg' to='/vendasanalytics' title='Vendas Analytics' hasBullet={false}></SidebarMenuItem>
      </SidebarMenuItemWithSub> */}
    </>
  )
}

export { SidebarMenuMain }
