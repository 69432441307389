import axios from 'axios'

import { ComposicaoInsumo, ServicoModel } from './_models'

const URL_API = `${process.env.REACT_APP_API_URL_ORCAMENTO}`

const LIST = `${URL_API}/listar`

export function list() {
  return axios.get<Array<ServicoModel>>(LIST)
}

export async function getInitialServices() {
  try {
    const response = await axios.get<ServicoModel>(`${URL_API}/servicos/iniciais`)
    return response.data
  } catch (error) {
    console.error(error)
  }
}
