/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'

import { KTSVG } from '../../../../../_metronic/helpers'
import { initialListValues as initialEmpreendimentos } from '../../../../modules/empreendimentos'
import { OrcamentoDetalheModel } from '../../../../modules/orcamentos/details'
// import { ServicoModel, initial } from '../../../../modules/servicos'
// import * as OrcamentoDetalheHelpers from '../../../../modules/orcamentos/details/OrcamentoDetalheHelpers'

type CreateProps = {
  orcamento: OrcamentoDetalheModel
  setOrcamento: (value: React.SetStateAction<OrcamentoDetalheModel>) => void
}

const Step1 = ({ setOrcamento }: CreateProps) => {
  const [empreendimentos] = useState(initialEmpreendimentos)

  const handleOrcamento = (e: any) => {
    const { name, value } = e.target
    if (name === 'idEmpreendimento') {
      const empreendimento = empreendimentos.find(empreendimento => {
        return empreendimento.idEmpreendimento === parseInt(value)
      })

      setOrcamento(prev => {
        const nomeEmpreendimento = empreendimento?.nomeEmpreendimento || ''
        const idEmpreendimento = empreendimento?.idEmpreendimento || 0
        // OrcamentoDetalheHelpers.setOrcamentoDetalhe(create)
        return { ...prev, nomeEmpreendimento, idEmpreendimento }
      })
    }

    setOrcamento(prev => {
      // OrcamentoDetalheHelpers.setOrcamentoDetalhe(create)
      return { ...prev, [name]: value }
    })
  }

  // useEffect(() => {
  //   if (orcamento.servicos.macroGrupos?.length === 0) {
  //     setOrcamento(prev => {
  //       const create = {
  //         ...prev,
  //         dataRevisao: new Date().toISOString().split('T')[0],
  //         dataOcorrencia: new Date().toISOString().split('T')[0],
  //         servicos: { name: '', macroGrupos: [] },
  //       }
  //       OrcamentoDetalheHelpers.setOrcamentoDetalhe(create)
  //       return create
  //     })
  //   }
  // }, [orcamento, setOrcamento])

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Nome do Orçamento</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Digite o nome do seu orçamento'
            />
          </label>

          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='tituloOrcamento'
            placeholder=''
            onChange={e => handleOrcamento(e)}
          />
        </div>
        {/*end::Form Group */}

        {/*begin::Form Group */}
        {/* <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Data do Orçamento</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Digite o nome do seu orçamento'
            />
          </label>
        </div> */}
        {/*end::Form Group */}

        {/*begin::Form Group */}
        <div className='fv-row'>
          {/* begin::Label */}
          <div className='accordion' id='kt_accordion_1'>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                <button
                  className='accordion-button fs-4 fw-bold collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_accordion_1_body_1'
                  aria-expanded='false'
                  aria-controls='kt_accordion_1_body_1'>
                  <span className='required'>Empreendimento</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione o Empreendimento'
                  />
                </button>
              </h2>

              {/* end::Label */}
              <div>
                {/*begin:Option */}
                <div
                  id='kt_accordion_1_body_1'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_1'
                  data-bs-parent='#kt_accordion_1'>
                  <div className='accordion-body'>
                    <div className='body scroll h-200px'>
                      {empreendimentos.map(empreendimento => {
                        return (
                          <div key={`${empreendimento.nomeEmpreendimento}-${empreendimento.idEmpreendimento}`}>
                            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                              <span className='d-flex align-items-center me-2'>
                                <span className='symbol symbol-50px me-6'>
                                  <span className='symbol-label bg-light-primary'>
                                    <KTSVG
                                      path='/media/icons/duotune/maps/map008.svg'
                                      className='svg-icon-1 svg-icon-danger'
                                    />
                                  </span>
                                </span>

                                <span className='d-flex flex-column'>
                                  <span className='fw-bolder fs-6'>{empreendimento.nomeEmpreendimento}</span>
                                </span>
                              </span>

                              <span className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='idEmpreendimento'
                                  value={`${empreendimento.idEmpreendimento}`}
                                  onChange={e => handleOrcamento(e)}
                                />
                              </span>
                            </label>
                            {/*end::Option */}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  {/*end::Option */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export { Step1 }
