import { FC, useState, useEffect, createContext, useContext, Dispatch, SetStateAction } from 'react'

import { WithChildren } from '../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../_metronic/layout/core'
import { ServicoModel } from './core/_models'
import { list } from './core/_requests'
import * as Servico from './ServicoHelpers'

type ServicoContextProps = {
  servicos: ServicoModel[] | []
  currentServico: ServicoModel | undefined
  saveServico: (servico: ServicoModel | undefined) => void
  setCurrentServico: Dispatch<SetStateAction<ServicoModel | undefined>>
}

const initServicoContextPropsState = {
  servicos: Servico.serviceList(),
  currentServico: Servico.get(),
  saveServico: () => {},
  setCurrentServico: () => {},
}

const ServicoContext = createContext<ServicoContextProps>(initServicoContextPropsState)

const useServico = () => {
  return useContext(ServicoContext)
}

const ServicoProvider: FC<WithChildren> = ({ children }) => {
  const [servicos] = useState<Array<ServicoModel>>(Servico.serviceList())
  const [currentServico, setCurrentServico] = useState<ServicoModel | undefined>()

  const saveServico = (servico: ServicoModel | undefined) => {
    setCurrentServico(servico)
    if (servico) {
      Servico.set(servico)
    }
  }

  return (
    <ServicoContext.Provider value={{ servicos, saveServico, currentServico, setCurrentServico }}>
      {children}
    </ServicoContext.Provider>
  )
}

const ServicoInit: FC<WithChildren> = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [servicos, setServicos] = useState<Array<ServicoModel>>(Servico.serviceList())
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const listing = async () => {
    try {
      await list().then(response => {
        const list = response.data
        Servico.setList(list)
      })
    } catch (error) {
      //console.log(error)
    } finally {
      setShowSplashScreen(false)
    }
    return Servico.serviceList()
  }

  useEffect(() => {
    listing()
  }, [servicos])

  return showSplashScreen ? <LayoutSplashScreen visible={showSplashScreen} /> : <>{children}</>
}

export { ServicoInit, useServico, ServicoProvider }
