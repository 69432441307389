import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getUserByToken, integration } from '../core/_requests'
import { useAuth } from '../core/Auth'

const Integration: FC = () => {
  const [loading, setLoading] = useState(false)
  const [initial, setValues] = useState({ usuario: 'user.name' })
  const { saveAuth, setCurrentUser, setInitialRoute } = useAuth()

  const { usuario, route } = useParams()

  const getIntegracao = async (usuario: string) => {
    try {
      const { data: auth } = await integration(usuario)
      saveAuth(auth)

      const { data: user } = await getUserByToken(auth.api_token)
      setCurrentUser(user)
      const path = route ? route : 'orcamentos'
      setInitialRoute(`/${path}`)
    } catch (error) {
      console.error(error)
      saveAuth(undefined)
    }
  }

  useEffect(() => {
    setLoading(true)

    if (usuario !== initial.usuario) {
      getIntegracao(usuario ? usuario : initial.usuario).then(() => {
        setLoading(false)
      })
    }

    return () => {
      setValues({ usuario: 'user.name' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {loading && (
        <span className='indicator-progress' style={{ display: 'block' }}>
          Por favor, aguarde. . .<span className='spinner-border spinner-border-sm align-middle ms-2' />
        </span>
      )}
    </div>
  )
}

export { Integration }
