import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuInnerWithSub title='Engenharia' to='/crafted' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}
        <MenuInnerWithSub
          title='Planejamentos'
          to='/crafted/pages'
          icon='/media/icons/duotune/general/gen005.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}>
          <MenuItem icon='/media/icons/duotune/files/fil024.svg' to='/pedidos' title='Pedidos' />
          <MenuItem icon='/media/icons/duotune/finance/fin010.svg' to='/orcamentos' title='Orçamentos' />
        </MenuInnerWithSub>
      </MenuInnerWithSub>

      {/* <MenuInnerWithSub title='Vendas' to='/apps' menuPlacement='bottom-start' menuTrigger='click'> */}
      {/* PAGES */}
      {/* <MenuInnerWithSub
          title='Dashboards'
          to='/crafted/pages'
          icon='/media/icons/duotune/abstract/abs027.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem icon='/media/icons/duotune/graphs/gra012.svg' to='/vendasanalytics' title='Vendas Analytics' />
        </MenuInnerWithSub> */}
      {/* </MenuInnerWithSub> */}
    </>
  )
}
