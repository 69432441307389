import axios from 'axios'
import { toast } from 'react-toastify'

import { Purchaser } from './_models'

const API_URL = process.env.REACT_APP_API_URL_ADMINCORP_PEDIDO
const API_PURCHASERS_URL = `${API_URL}/v1/purchaser/list`

export async function requestPurchaser() {
  return await axios
    .get<Array<Purchaser>>(API_PURCHASERS_URL)
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao consultar o Purchaser')
    })
}
