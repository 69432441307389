import React, { FC } from 'react'

import { PageTitle } from '../../../_metronic/layout/core'
import { Servico } from './Servico'

const ServicosWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Serviços</PageTitle>
      <Servico />
    </>
  )
}

export default ServicosWrapper
