import { FC, useState, useEffect, createContext, useContext, useCallback } from 'react'

import { WithChildren } from '../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../_metronic/layout/core'
import { Company } from './core/_models'
import { requestCompany } from './core/_requests'
import * as CompanyHelpers from './CompanyHelpers'

interface CompanyContextProps {
  company: Company
  companies: Company[]
  save: (company: Company) => void
  saveCompanies: (companies: Company[]) => void
}

const initCompanyContextPropsState = {
  companies: CompanyHelpers.getCompanies(),
  state: CompanyHelpers.getCompany(),
  company: CompanyHelpers.getCompany(),
  save: () => {},
  saveCompanies: () => {},
}

const CompanyContext = createContext<CompanyContextProps>(initCompanyContextPropsState)

const useCompany = () => {
  return useContext(CompanyContext)
}

const CompanyProvider: FC<WithChildren> = ({ children }) => {
  const [company, setCompany] = useState<Company>(CompanyHelpers.getCompany())
  const [companies, setCompanies] = useState<Company[]>(CompanyHelpers.getCompanies())

  const save = (company: Company) => {
    setCompany(company)
    CompanyHelpers.setCompanies
  }

  const saveCompanies = (companies: Company[]) => {
    setCompanies(companies)
    CompanyHelpers.setCompanies(companies)
  }

  useEffect(() => {}, [company])

  useEffect(() => {
    return () => {
      setCompany(CompanyHelpers.initialValues)
      save(CompanyHelpers.initialValues)
    }
  }, [])

  return (
    <CompanyContext.Provider
      value={{
        company,
        companies,
        save,
        saveCompanies,
      }}>
      {children}
    </CompanyContext.Provider>
  )
}

const CompanyInit: FC<WithChildren> = ({ children }) => {
  const { saveCompanies } = useCompany()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const CompanyRequest = useCallback(async () => {
    try {
      await requestCompany().then(result => {
        saveCompanies(result as Company[])
      })
    } catch (error) {
      console.error(error)
    } finally {
      setShowSplashScreen(false)
    }
  }, [saveCompanies])

  useEffect(() => {
    CompanyRequest()
  }, [CompanyRequest])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { CompanyInit, useCompany, CompanyProvider, CompanyContext }
