import { FC } from 'react'

import { KTSVG } from '../../../../_metronic/helpers'
import { OrderKpiRequest } from '../../../modules/orderkpi'
import { DropdownFilterKpi } from './DropdownFilterKpi'

interface FilterKpiProps {
  handler: (orderKpiRequest: OrderKpiRequest) => void
}

const FilterKpi: FC<FilterKpiProps> = ({ handler }) => {
  return (
    <div className='d-flex align-items-center gap-4 gap-lg-3 mb-6'>
      <div className='m-0'>
        <button
          type='button'
          className='btn btn-info border-0 me-n4 shadow-sm rounded-pill fs-4'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'>
          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-muted svg-icon-2x' />
          Filtrar
        </button>
        <DropdownFilterKpi handler={handler} />
      </div>
    </div>
  )
}

export { FilterKpi }
