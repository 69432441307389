import { FC } from 'react'

import { PageTitle } from '../../../_metronic/layout/core'
import { OrcamentoDetalheInit } from '../../modules/orcamentos/details/OrcamentoDetalhe'
import { OrcamentoDetalheView } from './OrcamentoDetalheView'

const OrcamentoDetalheWrapper: FC = () => {
  return (
    <OrcamentoDetalheInit>
      <PageTitle breadcrumbs={[]} />
      <OrcamentoDetalheView />
    </OrcamentoDetalheInit>
  )
}

export default OrcamentoDetalheWrapper
