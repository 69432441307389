import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import { FC, ChangeEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'

import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { useOrcamentoDetalhe, getBy } from '../../modules/orcamentos/details'
import { OrcamentoDetalheModel, currencyFormat } from '../../modules/orcamentos/details/core/_models'
import ActionsNavigatorOrcamento from './components/ActionsNavigatorOrcamento'
import EmpreendimentosSelect from './components/EmpreendimentosSelect'
import OrcamentoServicoTree from './components/OrcamentoServicoTree'
import { getLogo } from '../../modules/empreendimentos'

const OrcamentoDetalheView: FC = () => {
  const { orcamento, save } = useOrcamentoDetalhe()
  const [currentRevision, setCurrentRevision] = useState(
    Array.isArray(orcamento.revisoes) ? orcamento.revisoes.length - 1 : 0,
  )

  // TODO: Trocar para reducer
  const handleTitulo = (event: ChangeEvent<HTMLInputElement>) => {
    save({ ...orcamento, tituloOrcamento: event.target.value })
  }
  const handleObservacao = (event: ChangeEvent<HTMLTextAreaElement>) => {
    save({ ...orcamento, observacoes: event.target.value })
  }

  const handleDataOcorrencia = (event: ChangeEvent<HTMLInputElement>) => {
    save({ ...orcamento, dataOcorrencia: event.target.value })
  }

  const handleChangeRevision = (idOrcamento: number, idRevisao: number) => {
    getBy(idOrcamento, idRevisao, true).then(results => {
      const response = results as OrcamentoDetalheModel
      save(response)
    })
  }

  const navigate = (action: string) => {
    toast.info('Aguarde, carregando revisão...')

    switch (action) {
      case 'next':
        if (currentRevision === orcamento.revisoes.length - 1) {
          toast.info('Você já está na última revisão!')
          return
        }
        const nextRevision = currentRevision + 1
        setCurrentRevision(nextRevision)
        handleChangeRevision(orcamento.idOrcamento, orcamento.revisoes[nextRevision].idRevisao)

        break
      case 'previous':
        if (currentRevision === 0) {
          toast.info('Você já está na primeira revisão!')
          return
        }
        const previousRevision = currentRevision - 1
        setCurrentRevision(previousRevision)
        handleChangeRevision(orcamento.idOrcamento, orcamento.revisoes[previousRevision].idRevisao)

        break

      default:
        break
    }
  }

  // const handleDataOcorrencia: DatePickerProps['onChange'] = (date, dateString) => {
  //   save({ ...orcamento, dataOcorrencia: dateString })
  // }

  return (
    <>
      <ToastContainer style={{ width: 'auto', fontSize: '16px' }} />
      <div className='d-flex flex-stack container-fluid py-5'>
        <div className='d-flex flex-shrink-0 position-relative'>
          <img
            src={getLogo(orcamento.nomeEmpreendimento.split(' ')[0].toLowerCase())}
            style={{ maxWidth: '175px', maxHeight: '125px' }}
            alt={`Logo do ${orcamento.tituloOrcamento}`}
          />
        </div>
        <ActionsNavigatorOrcamento />
      </div>
      <div className='card mb-2'>
        <div className='card-header border-0 pt-5'>
          <div className='d-flex flex-row'>
            <h3 className='card-title'>{orcamento.tituloOrcamento}</h3>
          </div>
          <div className='d-flex flex-row'>
            {Array.isArray(orcamento.revisoes) && currentRevision > 0 && (
              <button
                className='btn btn-light-info btn-outline btn-outline-dashed me-2 mb-2'
                onClick={() => {
                  navigate('previous')
                }}>
                <i className='bi bi-arrow-left-short fs-2' />
              </button>
            )}
            <button className='btn btn-light-info btn-outline btn-outline-dashed me-2 mb-2' disabled>
              Revisão ({orcamento.idRevisao}) {orcamento.numeroRevisao} de
              {Array.isArray(orcamento.revisoes) && orcamento.revisoes.length > 0 ? orcamento.revisoes.length - 1 : 0}
            </button>
            {Array.isArray(orcamento.revisoes) && currentRevision !== orcamento.revisoes.length - 1 && (
              <button
                className='btn btn-light-info btn-outline btn-outline-dashed me-2 mb-2'
                onClick={() => {
                  navigate('next')
                }}>
                <i className='bi bi-arrow-right-short fs-2' />
              </button>
            )}
            <div className='d-flex flex-row'>
              <div className='position-relative'>
                <button className='btn btn-info btn-outline btn-outline-dashed ms-5 me-2 mb-2' disabled>
                  <div className='text-center p-1 me-8'>{currencyFormat(orcamento.custoTotal)}</div>
                  <div className='position-absolute translate-middle-y top-50 end-0 pb-1 me-3'>
                    <KTSVG path='/media/icons/duotune/finance/fin010.svg' className='svg-icon-muted svg-icon-2hx' />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='form fv-plugins-bootstrap5 fv-plugins-framework' id='kt_contact_form'>
            <div className='d-flex flex-row flex-column-fluid'>
              <div className='d-flex flex-row-fluid flex-center'>
                <div className='flex-column-fluid'>
                  <div className='form-floating mb-7 me-4'>
                    <div className='position-relative'>
                      <input
                        type='text'
                        className='d-flex form-control form-control-solid text-center'
                        value={orcamento.idOrcamento}
                        id='codigoFloatInput'
                        disabled
                      />
                      <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs001.svg'
                          className='svg-icon-muted svg-icon-2hx'
                        />
                      </div>
                    </div>
                    <label htmlFor='codigoFloatInput'>Codigo</label>
                  </div>
                </div>
                <div className='flex-column-fluid'>
                  <div className='form-floating mb-7 me-4'>
                    <div className='position-relative'>
                      <input
                        type='text'
                        className={`d-flex form-control form-control-solid  text-center ${
                          orcamento.flagFechado === 1 ? '' : 'text-primary'
                        }`}
                        value={orcamento.tituloOrcamento}
                        onChange={e => handleTitulo(e)}
                        placeholder='Nome do Orçamento'
                        id='tituloOrcamentoFloatInput'
                        disabled={orcamento.flagFechado === 1}
                      />
                      <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                        <KTSVG path='/media/icons/duotune/art/art001.svg' className='svg-icon-muted svg-icon-2hx' />
                      </div>
                    </div>
                    <label htmlFor='tituloOrcamentoFloatInput'>Título</label>
                  </div>
                </div>
                <div className='flex-column-fluid'>
                  <div className='form-floating mb-7 me-4'>
                    <div className='position-relative'>
                      <input
                        type='text'
                        className={`form-control form-control-solid text-center ${
                          orcamento.flagFechado === 1 ? '' : 'text-primary'
                        }`}
                        placeholder='Data da Ocorrência do Orçamento'
                        id='dataOcorrenciaFloatInput'
                        value={moment(orcamento.dataOcorrencia).format('DD/MM/YYYY')}
                        onChange={e => handleDataOcorrencia(e)}
                        disabled={orcamento.flagFechado === 1}
                      />
                      <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                        <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-muted svg-icon-2hx' />
                      </div>
                    </div>
                    <label htmlFor='dataOcorrenciaFloatInput'>Data do Orçamento</label>
                  </div>
                </div>
                <div className='flex-column-fluid'>
                  <EmpreendimentosSelect />
                </div>
              </div>
            </div>
            <div className='flex-column-fluid'>
              <div className='ml-1 me-4'>
                <textarea
                  rows={2}
                  value={orcamento.observacoes?.length > 0 ? orcamento.observacoes : ''}
                  className={`form-control form-control-solid ${orcamento.flagFechado === 1 ? '' : 'text-primary'}`}
                  name='message'
                  placeholder='Observações'
                  onChange={e => handleObservacao(e)}
                  title='Por favor, digite a observação do orçamento'
                  disabled={orcamento.flagFechado === 1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrcamentoServicoTree />
    </>
  )
}
export { OrcamentoDetalheView }
