import { FC } from 'react'

import { currencyFormat } from '../../../modules/orcamentos/details/core/_models'
import { ComposicaoInsumoServico } from '../../../modules/servicos/core/_models'

type ComposicaoViewProps = {
  composicao: ComposicaoInsumoServico[]
}

const ComposicaoView: FC<ComposicaoViewProps> = ({ composicao }) => {
  return (
    <div className='table table-responsive-lg'>
      <table className='table table-striped gy-7 gs-7'>
        <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
            <th className='min-w-20px'>Código</th>
            <th className='min-w-500px'>Insumo</th>
            <th className='min-w-30px'>Vl.unitário</th>
            <th className='min-w-30px'>Quantidade</th>
          </tr>
        </thead>
        <tbody>
          {composicao.map(insumo => (
            <tr key={insumo.id_material} className='bg-hover-light-primary'>
              <td>{insumo.id_material}</td>
              <td>{insumo.nome_material}</td>
              <td>
                <div className='input-group mb-5'>
                  <span className='input-group-text'>R$</span>
                  <input
                    type='text'
                    className='form-control'
                    id={`precoUnitario_${insumo.id_material}`}
                    value={currencyFormat(insumo.valor_material) || '1,00'}
                    aria-label='Preço unitário do valor do material'
                    name='precoUnitario'
                    disabled
                  />
                </div>
              </td>
              <td>
                <div className='input-group mb-5'>
                  <input
                    type='text'
                    className='form-control'
                    id={`quantidade_${insumo.id_material}`}
                    value={insumo.qtd_material || 1}
                    name='quantidade'
                    disabled
                  />
                  <span className='input-group-text'>{insumo.siglaUnidadeServico}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { ComposicaoView }
