import { ServicoModel, ServicoViewDTO } from '../../../servicos/core/_models'

export interface OrcamentoDetalheModel {
  idOrcamento: number
  liberacaoRequisicao: number
  nomeEmpreendimento: string
  idEmpreendimento: number
  tituloOrcamento: string
  dataOcorrencia: string
  idRevisao: number
  flagFechado: number
  custoTotal: number
  dataRevisao: string
  porcentagemTolerancia: number
  numeroRevisao: number
  observacoes: string
  historico: Historico[] | []
  servicos: ServicoModel
  revisoes: Revisao[]
  servicosDTO?: ServicoViewDTO | []
}

export interface Revisao {
  idRevisao: number
}

export interface Historico {
  data_historico: string
  id_or: number
  id_usuario: number
  id_historico: number
  flag_automatico: boolean
  desc_usuario: string
  desc_historico: string

  dataHistorico: string
  descHistorico: string
  descUsuario: string
  flagAutomatico: number
  idHistorico: number
  idRevisao: number
  idUsuario: number
}

export interface OrcamentoDTO {
  id_orcamento: number
  id_revisao: number
  id_empreendimento: number
  numero_revisao: number
  titulo_orcamento: string
  data_ocorrencia: string
  porcentagem_tolerancia: number
  data_revisao: string
  liberacao_requisicao: number
  observacoes: string
  flag_fechado: number

  servicos: ServicoDTO[]
}

export interface ServicoDTO {
  tolerancia: number
  id_servico: number
  idunidadeservico: number
  quantidade: number
  cod_servico: string
  idpkservico: number
  valor_unitario: number
  observacao: string
  composicao: ComposicaoDTO[]
}

export interface ComposicaoDTO {
  id_unidade_material: number
  qtd_material: number
  valor_material: number
  id_material: number
  nome_material: string
}

export interface OrcamentoResponse {
  response: OrcamentoDetalheModel
  statusCode: number
  message: string
  detail: string
}

export function currencyFormat(value: string | number): string {
  return Number(value).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export function currencyToFloat(value: string): number {
  return parseFloat(value.replace(/[^\d.,]/g, '').replace(',', '.'))
}

export function toInt(value: string) {
  return isNaN(parseInt(value)) ? 0 : parseInt(value)
}
