import { FC, useMemo } from 'react'

import { KTSVG } from '../../../../_metronic/helpers'
import { OrderKpiRequest, useOrderKpi } from '../../../modules/orderkpi'

type Props = {
  className: string
  color: string
  handler: (orderKpiRequest: OrderKpiRequest) => void
}

// create a function with return theses names based on numbers light -> 1, dark -> 2, success -> 3, info -> 4, warning -> 5, danger -> 6

const getStyle = (color: number) => {
  switch (color) {
    case 1:
      return 'bg-light-warning bg-hover-warning text-gray-500 text-hover-white'
    case 2:
      return 'bg-light-secondary bg-hover-secondary text-gray-500 text-hover-white'
    case 3:
      return 'bg-light-success bg-hover-success text-gray-500 text-hover-white'
    case 4:
      return 'bg-light-info bg-hover-info text-gray-500 text-hover-white'
    case 5:
      return 'bg-light-danger bg-hover-danger text-gray-500 text-hover-white'
    case 6:
      return 'bg-light-primary bg-hover-primary text-gray-500 text-hover-white'
    default:
      return 'bg-light bg-hover-light text-hover-light'
  }
}

// bg-light-warning bg-hover-light-info text-hover-info

const Status: FC<Props> = ({ className, color, handler }) => {
  const { orderKpi } = useOrderKpi()
  const totalAmount = useMemo(() => {
    return orderKpi.orderSituation.reduce((total, situation) => total + situation.amount, 0)
  }, [orderKpi.orderSituation])

  const formattedTotalAmount = useMemo(() => (totalAmount !== null ? totalAmount.toLocaleString() : ''), [totalAmount])

  return (
    <div className={`card mb-5 ${className}`}>
      <div className={`px-9 pt-7 card-rounded h-200px w-100 bg-${color}`}>
        {/* begin::Heading */}
        <div className='d-flex flex-stack'>
          <h3 className='m-0 text-white fw-bold fs-3'>Pedidos por Status</h3>
        </div>
        {/* end::Heading */}
        {/* begin::Balance */}
        <div className='d-flex text-center flex-column text-white pt-8'>
          <span className='fw-semibold fs-7'>Quantidade Total de Pedidos</span>
          <span className='fw-bold fs-2x pt-1'>{totalAmount.toLocaleString()}</span>
        </div>
        {/* end::Balance */}
      </div>
      <div className='card-body p-0'>
        <div className='card-p mt-n20 position-relative'>
          <div className='row g-5'>
            <div className='col-md-6'>
              {orderKpi.orderSituation.slice(0, 3).map(situation => (
                <div key={situation.situationId} className='m-0'>
                  {situation.amount !== undefined && ( // Check if amount is not undefined
                    <div className={`col ${getStyle(situation.situationId)} px-7 py-9 rounded-2 mb-8`}>
                      <KTSVG path={`/media/icons/pedidos/${situation.situationId}.svg`} className=' svg-icon-3hx' />
                      <p className='fw-semibold fs-6'>{situation.situation}</p>
                      <p className='fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>{situation.amount.toLocaleString()}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className='col-md-6'>
              {orderKpi.orderSituation.slice(3, 6).map(situation => (
                <div key={situation.situationId} className='m-0'>
                  {situation.amount !== undefined && ( // Check if amount is not undefined
                    <div className={`col ${getStyle(situation.situationId)} px-7 py-9 rounded-2 mb-8`}>
                      <KTSVG path={`/media/icons/pedidos/${situation.situationId}.svg`} className=' svg-icon-3hx' />
                      <p className='fw-semibold fs-6'>{situation.situation}</p>
                      <p className='fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>{situation.amount.toLocaleString()}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Status }
