import axios from 'axios'
import { toast } from 'react-toastify'

import { Family } from '../index'

const API_URL = process.env.REACT_APP_API_URL_ADMINCORP_PEDIDO //ADICIONAR no .env.development
const API_FAMILIES_URL = `${API_URL}/v1/family/list`

export async function requestFamily() {
  return await axios
    .get<Array<Family>>(API_FAMILIES_URL)
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao consultar o Family')
    })
}
