import { FC } from 'react'

import { useOrderKpi } from '../../../modules/orderkpi'
import { currencyFormat } from '../../../utils/Utils'

const OrderSupplier: FC = () => {
  const { orderKpi } = useOrderKpi()
  const sortedOrderSupplier = [...orderKpi.orderSupplier].sort((a, b) => a.supplierName.localeCompare(b.supplierName))
  return (
    <div className='table-responsive' style={{ overflowX: 'auto', maxHeight: '1265px' }}>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead style={{ position: 'sticky', top: '0', zIndex: 1 }}>
          <tr className='fw-bold fs-4 text-muted border-bottom-2 border-gray-200 bg-gray-100'>
            <th className='px-5'>Fornecedor</th>
            <th className='px-5'>Quantidade</th>
            <th className='px-5'>Preço</th>
          </tr>
        </thead>
        <tbody>
          {sortedOrderSupplier.map(supplier => (
            <tr key={supplier.supplierId}>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200 px-5'>
                      {supplier.supplierName}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex flex-column w-100 me-2 align-items-center'>
                  <div className='d-flex flex-stack mb-2'>
                    <span className='text-muted me-2 fs-6 fw-semibold'>{supplier.amount}</span>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex flex-column w-100 me-2'>
                  <div className='d-flex flex-stack mb-2'>
                    <span className='text-muted me-1 fs-9 fw-semibold'>R$ {currencyFormat(supplier.orderSum)}</span>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { OrderSupplier }
