import { FC } from 'react'

import { OrderKpiRequest, useOrderKpi } from '../../../modules/orderkpi'
import { Company } from '../../../modules/company'

interface ComboBoxPCompanyProps {
  companies: Company[]
  handler: (orderKpiRequest: OrderKpiRequest) => void
}

const ComboBoxCompany: FC<ComboBoxPCompanyProps> = ({ companies, handler }) => {
  const { kpiRequest } = useOrderKpi()

  const onChange = (event: any) => {
    handler({ ...kpiRequest, companyId: event.target.value })
  }

  const onSearch = (value: string) => {}
  return (
    <select
      className='form-select form-select-solid'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      placeholder='Empresa'
      onChange={onChange}>
      {companies.map(company => (
        <option key={company.companyId} value={company.companyId}>
          {company.companyName}
        </option>
      ))}
    </select>
  )
}

export { ComboBoxCompany }
