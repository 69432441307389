import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import {
  useOrcamentoDetalhe,
  get,
  remove,
  close,
  release,
  revision,
  save as remoteSave,
} from '../../../modules/orcamentos/details'
import { OrcamentoDetalheModel } from '../../../modules/orcamentos/details'
import { buildDTO } from '../../../modules/orcamentos/list'

const URL_ORCAMENTOS_IMPRESSAO = `${process.env.REACT_APP_URL_ORCAMENTOS_IMPRESSAO}`

const ActionsNavigatorOrcamento: FC = () => {
  const { orcamento, save } = useOrcamentoDetalhe()
  const [showSplashScreen, setShowSplashScreen] = useState(false)

  const handleUpdate = (last = false, show = false) => {
    setShowSplashScreen(true)
    get(buildDTO(orcamento), last).then(results => {
      const response = results as OrcamentoDetalheModel
      save(response)
      if (show) {
        setShowSplashScreen(false)
        toast.info('Orçamento atualizado com sucesso!')
      }
    })
  }
  const handleSave = () => {
    setShowSplashScreen(true)
    remoteSave(buildDTO(orcamento)).then(response => {
      setTimeout(() => {
        handleUpdate(true, true)
      }, 500)
      return response
    })
  }

  const handleDelete = () => {
    setShowSplashScreen(true)
    remove(buildDTO(orcamento)).then(() => {
      setTimeout(() => {
        handleUpdate(true, true)
      }, 500)
    })
  }

  const handleClose = () => {
    setShowSplashScreen(true)
    close(buildDTO({ ...orcamento, flagFechado: 1 })).then(() => {
      setTimeout(() => {
        handleUpdate(true, true)
      }, 500)
    })
  }
  const handleRelease = () => {
    setShowSplashScreen(true)
    release(buildDTO({ ...orcamento, liberacaoRequisicao: 1 })).then(() => {
      setTimeout(() => {
        handleUpdate(true, true)
      }, 500)
    })
  }

  const handleRevision = () => {
    setShowSplashScreen(true)
    revision(buildDTO(orcamento)).then(() => {
      setTimeout(() => {
        handleUpdate(true, true)
      }, 500)
    })
  }

  return (
    <div className='card mb-2'>
      <LayoutSplashScreen visible={showSplashScreen} />
      <div className='card-body'>
        <Link to='/orcamentos' className='ms-1 mt-1 btn btn-info'>
          <i className='bi bi-arrow-left-short fs-2' />
          {orcamento.idOrcamento === 0 ? `Cancelar` : `Voltar`}
        </Link>
        {orcamento.idOrcamento !== 0 && (
          <button onClick={() => handleUpdate(true, true)} className='ms-1 mt-1 btn btn-secondary'>
            <i className='bi bi-arrow-repeat fs-2' />
            Atualizar
          </button>
        )}
        {orcamento.flagFechado === 0 && (
          <button
            onClick={handleSave}
            disabled={orcamento.flagFechado ? orcamento.flagFechado > 0 : false}
            className='ms-1 mt-1 btn btn-success'>
            <i className='bi bi-save fs-2' />
            {orcamento.idOrcamento === 0 ? `Criar Orçamento` : `Salvar`}
          </button>
        )}
        {orcamento.flagFechado > 0 && (
          <button onClick={handleRevision} className='ms-1 mt-1 btn btn-primary'>
            <i className='bi bi-file-earmark-plus fs-2' />
            Criar Nova Revisão
          </button>
        )}
        {orcamento.idOrcamento !== 0 && orcamento.flagFechado === 0 && (
          <button onClick={handleClose} className='ms-1 mt-1 btn btn-danger'>
            <i className='bi bi-x fs-2' />
            Fechar Orçamento
          </button>
        )}
        {orcamento.idOrcamento !== 0 && (
          <a
            href={`${URL_ORCAMENTOS_IMPRESSAO}&idOrcamento=${orcamento.idOrcamento}&idOr=${orcamento.idRevisao}`}
            target={'_blank'}
            className='ms-1 mt-1 btn btn-info'
            rel='noreferrer'>
            <i className='bi bi-printer fs-2' />
            Imprimir
          </a>
        )}
        {orcamento.idOrcamento !== 0 && orcamento.flagFechado > 0 && (
          <button onClick={handleRelease} className='ms-1 mt-1 btn btn-success'>
            <i className='bi bi-bookmark-check fs-2' />
            Liberar Requisições
          </button>
        )}
        {orcamento.idOrcamento !== 0 &&
          Array.isArray(orcamento.revisoes) &&
          orcamento.revisoes.length > 0 &&
          orcamento.revisoes[0].idRevisao !== orcamento.idRevisao && (
            <button onClick={handleDelete} className='ms-1 mt-1 btn btn-danger'>
              <i className='bi bi-trash3 fs-2' />
              Excluir Revisão
            </button>
          )}
      </div>
    </div>
  )
}

export default ActionsNavigatorOrcamento

/*

const showToastSuccessMessage = () => {
  toast.success(`Orçamento do ${orcamento.tituloOrcamento} foi criado com sucesso!`, {
    toastId: 'success',
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  })
}
const showToastInfoMessage = () => {
  toast.info(`Processando`, {
    toastId: 'info',
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  })
}
const showToastErrorMessage = () => {
  toast.error(`Erro ao salvar o orçamento ${orcamento.tituloOrcamento} `, {
    toastId: 'error',
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  })
}
const showToastWarnMessage = () => {
  toast.warn(`Atenção faltam dados ao orçamento ${orcamento.tituloOrcamento} `, {
    toastId: 'warn',
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  })
}
*/
