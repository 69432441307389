import moment from 'moment'

import { Historico } from '../../../modules/orcamentos/details/core/_models'
type HistoricoProps = {
  historicos: Historico[]
}
// TODO: Refatorar este componente para que ele possa ser reutilizado e diminuir a complexidade

function recalculatePosition(textChange: number): number {
  let response = textChange
  if (response > 3) {
    response -= 4
    return recalculatePosition(response)
  }
  return response
}
const HistoricoTab = ({ historicos }: HistoricoProps) => {
  function changeColor(classIndex: number): string {
    classIndex = recalculatePosition(classIndex)
    switch (classIndex) {
      case 0:
        return 'fa fa-genderless text-danger fs-1'
      case 1:
        return 'fa fa-genderless text-success fs-1'
      case 2:
        return 'fa fa-genderless text-primary fs-1'
      case 3:
        return 'fa fa-genderless text-warning fs-1'
      default:
        return 'fa fa-genderless text-primary fs-1'
    }
  }

  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Histórico de atividades</span>
          <span className='text-muted fw-semibold fs-7'>{historicos.length} registros de atividade</span>
        </h3>
      </div>
      <div className='card-body pt-5'>
        <div className='timeline-label'>
          {historicos.length &&
            historicos.map((historico, position) => (
              <div className='timeline-item' key={historico.idHistorico}>
                <div className='timeline-label fw-bold text-gray-800 fs-6'>
                  <div className='timeline-label fw-bold text-gray-800 fs-6'>
                    {moment(historico.dataHistorico).format('HH:mm')}
                  </div>
                  <div className='timeline-label fw-bold text-gray-500 fs-9'>
                    {moment(historico.dataHistorico).format('DD/MM/YY')}
                  </div>
                </div>
                <div className='timeline-badge'>
                  <i className={changeColor(position)} />
                </div>
                <div className='timeline-content'>
                  <div className='fw-bold text-gray-800 ps-3'>{historico.descUsuario} </div>
                  <div className='fw-bold text-gray-800 ps-3'>{historico.descHistorico}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export { HistoricoTab }
