import { Family } from './core/_models'

const FAMILY_LOCAL_STORAGE_KEY = 'kt-family-react-v'
const FAMILIES_LOCAL_STORAGE_KEY = 'kt-families-react-v'

const initialValues: Family = {
  familyId: 0,
  familyName: '',
  orderQuantity: 0,
}

const getFamilies = (): Family[] => {
  if (!localStorage) {
    return [initialValues]
  }

  const lsValue: string | null = localStorage.getItem(FAMILIES_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return [initialValues]
  }
  try {
    const lsValue = localStorage.getItem(FAMILIES_LOCAL_STORAGE_KEY)
    if (lsValue) {
      const families: Family[] = JSON.parse(lsValue) as Family[]
      if (families) {
        return families
      }
    }
  } catch (error) {
    console.error('FAMILIES LOCAL STORAGE PARSE ERROR', error)
  }

  return [initialValues]
}

const setFamilies = (families: Family[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(families)
    localStorage.setItem(FAMILIES_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('FAMILIES LOCAL STORAGE SAVE ERROR', error)
  }
}

const getFamily = (): Family => {
  if (!localStorage) {
    return initialValues
  }

  const lsValue: string | null = localStorage.getItem(FAMILY_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValues
  }

  try {
    const family: Family = JSON.parse(lsValue) as Family
    if (family) {
      // You can easily check family_token expiration also
      return family
    }
  } catch (error) {
    console.error('FAMILY LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValues
}

const setFamily = (family: Family | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(family)
    localStorage.setItem(FAMILY_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('FAMILY LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeFamily = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(FAMILY_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('FAMILY LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {
  getFamily,
  setFamily,
  removeFamily,
  setFamilies,
  getFamilies,
  initialValues,
  FAMILY_LOCAL_STORAGE_KEY,
  FAMILIES_LOCAL_STORAGE_KEY,
}
