import { ChangeEvent, FC, useState } from 'react'
import moment from 'moment'

import { OrderKpiRequest, useOrderKpi } from '../../../modules/orderkpi'
import { ComboBoxBuilding } from './ComboBoxBuilding'
import { ComboBoxFamily } from './ComboBoxFamily'
import { ComboBoxCompany } from './ComboBoxCompany'
import { Calendar } from './Calendar'
import { ComboBoxPurchaser } from './ComboBoxPurchaser'

interface DropdownFilterKpiProps {
  handler: (orderKpiRequest: OrderKpiRequest) => void
}

const DropdownFilterKpi: FC<DropdownFilterKpiProps> = ({ handler }) => {
  const { kpiRequest, companies, families, buildings, purchasers } = useOrderKpi()
  const [startDate, setStartDate] = useState(kpiRequest.startDate)
  const [finalDate, setFinalDate] = useState(kpiRequest.finalDate)

  const startDateOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const date = moment(event.target.value).format('YYYY-MM-DD')
    setStartDate(date)
    handler({ ...kpiRequest, startDate: date })
  }
  const finalDateOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const date = moment(event.target.value).format('YYYY-MM-DD')
    setFinalDate(date)
    handler({ ...kpiRequest, finalDate: date })
  }
  return (
    <div className='menu menu-sub menu-sub-dropdown w-450px w-md-400px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filtros</div>
      </div>

      <div className='separator border-gray-200' />

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Obras:</label>

          <div>
            <ComboBoxBuilding buildings={buildings} handler={handler} />
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fw-bold'>Compradores:</label>

          <div>
            <ComboBoxPurchaser purchasers={purchasers} handler={handler} />
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fw-bold'>Famílias:</label>

          <div>
            <ComboBoxFamily handler={handler} />
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fw-bold'>Empresa:</label>

          <div>
            <ComboBoxCompany companies={companies} handler={handler} />
          </div>
        </div>
        <div className='row mb-10'>
          <div className='col-md-6'>
            <label className='form-label fw-bold'>Data inicial:</label>

            <div>
              <Calendar onChange={startDateOnChange} date={startDate} />
            </div>
          </div>
          <div className='col-md-6'>
            <label className='form-label fw-bold'>Data final:</label>

            <div>
              <Calendar onChange={finalDateOnChange} date={finalDate} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { DropdownFilterKpi }
