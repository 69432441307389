import { FC, useState } from 'react'

import { KTSVG } from '../../../../_metronic/helpers'
import { Empreendimento, list } from '../../../modules/empreendimentos'
import { useOrcamentoDetalhe } from '../../../modules/orcamentos/details'

const EmpreendimentosSelect: FC = () => {
  const { orcamento, save } = useOrcamentoDetalhe()
  const [selected, setSelected] = useState(`${orcamento.idEmpreendimento}`)
  const [empreendimentos] = useState<Empreendimento[]>(list)

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    if (!isNaN(parseInt(value))) {
      save({ ...orcamento, idEmpreendimento: parseInt(value) })
    }
    setSelected(value)
  }

  return (
    <div className='form-floating mb-7 me-4'>
      <div className='position-relative'>
        <select
          value={selected}
          onChange={handleChange}
          id='selectEmpreendimentoFloatInput'
          disabled={orcamento.flagFechado === 1}
          className={`form-control form-control-solid text-center ${orcamento.flagFechado === 1 ? '' : 'text-primary'}`}
          aria-label='Lista de Empreendimentos'>
          <option value='0'>Selecione um Empreendimento</option>
          {empreendimentos.map(empreendimento => (
            <option key={empreendimento.idEmpreendimento} value={empreendimento.idEmpreendimento}>
              {empreendimento.nomeEmpreendimento}
            </option>
          ))}
        </select>
        <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
          <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-muted svg-icon-2hx' />
        </div>
      </div>
      <label htmlFor='selectEmpreendimentoFloatInput'>Empreendimento</label>
    </div>
  )
}

export default EmpreendimentosSelect
