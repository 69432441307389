import axios from 'axios'
import { toast } from 'react-toastify'

import { Empreendimento, EmpreendimentoDTO } from './_models'

const API_URL_ORCAMENTOS = `${process.env.REACT_APP_API_URL_ORCAMENTO}`

export async function getAll() {
  return await axios
    .get<EmpreendimentoDTO[]>(`${API_URL_ORCAMENTOS}/empreendimentos`)
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao listar os empreendimentos')
    })
}

export function convertTo(empreendimento: EmpreendimentoDTO): Empreendimento {
  return {
    idEmpreendimento: empreendimento.id_empreendimento,
    nomeEmpreendimento: empreendimento.nome_empreendimento,
    idEmpresa: empreendimento.id_empresa,
    nomeFantasia: empreendimento.nome_fantasia,
  } as Empreendimento
}
