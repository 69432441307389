import { FC } from 'react'

import { PageTitle } from '../../../_metronic/layout/core'
import { OrderKpi } from './OrderKpi'
import { OrderKpiInit } from '../../modules/orderkpi'

const OrderKpiWrapper: FC = () => {
  return (
    <OrderKpiInit>
      <PageTitle breadcrumbs={[]}>Pedidos</PageTitle>
      <OrderKpi />
    </OrderKpiInit>
  )
}

export default OrderKpiWrapper
