import { FC } from 'react'

import { useOrderKpi } from '../../../modules/orderkpi'

const OrderPurchaser: FC = () => {
  const { orderKpi } = useOrderKpi()
  const sortedOrderPurchaser = [...orderKpi.orderPurchaser].sort((a, b) =>
    a.purchaserName.localeCompare(b.purchaserName),
  )

  return (
    <div className='table-responsive' style={{ overflowX: 'auto', maxHeight: '400px' }}>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead style={{ position: 'sticky', top: '0', zIndex: 1 }}>
          <tr className='fw-bold fs-4 text-muted border-bottom-2 border-gray-200 bg-gray-100'>
            <th className='px-5'>Comprador</th>
            <th className='px-5'>Quantidade</th>
          </tr>
        </thead>
        <tbody>
          {sortedOrderPurchaser.map((purchaser, index) => (
            <tr key={purchaser.purchaserId}>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200 px-5'>
                      {purchaser.purchaserName}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex flex-column w-100 me-2 align-items-center'>
                  <div className='d-flex flex-stack mb-2'>
                    <span className='text-muted me-2 fs-6 fw-semibold'>{purchaser.amount}</span>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { OrderPurchaser }
