import { useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Link } from 'react-router-dom'
import Button from 'antd/lib/button'
import moment from 'moment'

import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { OrcamentoModel } from '../../modules/orcamentos/list/core/_models'
import * as orcamentoHelper from '../../modules/orcamentos/list/OrcamentoHelpers'
import { CreateAppModal } from './modal-novo-orcamento'
import { getLogo } from '../../modules/empreendimentos'

const OrcamentoListagem = () => {
  const LISTA_QUANTIDADE_5 = 5
  const LISTA_QUANTIDADE_10 = 10
  const LISTA_QUANTIDADE_25 = 25
  const LISTA_QUANTIDADE_50 = 50
  const LISTA_QUANTIDADE_100 = 100

  const [showCreateAppModal, setShowCreateAppModal] = useState(false)
  const [orcamentos] = useState<Array<OrcamentoModel>>(orcamentoHelper.listOrcamentos())
  const [busca, setBusca] = useState('')
  const [orcamentosPorPagina, setOrcamentosPorPagina] = useState(LISTA_QUANTIDADE_100)
  const [paginaAtual, setPaginaAtual] = useState(0)
  const [loadings, setLoadings] = useState<boolean[]>([])

  const paginas = Math.ceil(orcamentos.length / orcamentosPorPagina)
  const startIndex = paginaAtual * orcamentosPorPagina
  const endIndex = startIndex + orcamentosPorPagina
  const [orcamentoAtual, setOrcamentoAtual] = useState(orcamentos.slice(startIndex, endIndex))
  const proximaPagina = paginaAtual + 1
  const paginaAnterior = paginaAtual - 1

  const enterLoading = (index: number) => {
    setLoadings(prevLoadings => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = true
      return newLoadings
    })
    setShowCreateAppModal(true)
    setTimeout(() => {
      setLoadings(prevLoadings => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = false
        return newLoadings
      })
    }, 500)
  }
  // quero uma função que receba o array de empreendimentos e escolha qual logo mostrar de acordo com o id do empreendimento

  const buscar = (orcamento: OrcamentoModel[], event: React.ChangeEvent<HTMLInputElement>) => {
    setBusca(event.target.value)

    if (event.target.value === '') {
      setOrcamentoAtual(orcamentos.slice(startIndex, endIndex))
    } else {
      setOrcamentoAtual(
        orcamento
          .filter(orcamento => {
            return (
              orcamento.tituloOrcamento.toLowerCase().includes(busca.toLowerCase()) ||
              orcamento.idOrcamento.toString().toLowerCase().includes(busca.toLowerCase()) ||
              orcamento.numeroRevisao.toString().toLowerCase().includes(busca.toLowerCase())
            )
          })
          .slice(startIndex, endIndex),
      )
    }
  }

  useEffect(() => {
    setPaginaAtual(0)
  }, [orcamentos, orcamentosPorPagina])

  return (
    <>
      <Button className='btn btn-sm fw-bold text-black-50 btn-success mb-10' onClick={() => enterLoading(1)}>
        Novo Orçamento
      </Button>

      <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />

      <div className='card mb-15'>
        <div className='card mb-5 mb-xl-4'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Lista de Orçamentos</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>Temos {orcamentos.length} orçamento(s)</span>
            </h3>
            <div className='card-toolbar'>
              <div className='input-group pb-3 mb-10 mt-10 w-250px h-25px'>
                <input
                  type='search'
                  className='form-control form-control-sm w-200px h-30px'
                  placeholder='Pesquisar'
                  onChange={event => buscar(orcamentos, event)}
                />
                <button className='border btn btn-icon btn-light-primary h-35px'>
                  <i className='lab la-sistrix' />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-9-check'
                      />
                    </div>
                  </th>
                  <th className='min-w-200px'>Orçamento</th>
                  <th className='min-w-200px'>Código</th>
                  <th className='min-w-150px'>Revisão Atual</th>
                  <th className='min-w-150px'>Data da Revisão</th>
                  <th className='min-w-200px'>Custo total</th>
                  <th className='min-w-100px text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {/*  {orcamentoAtual
                  .filter(orcamento => orcamento.tituloOrcamento.toLowerCase().includes(busca.toLowerCase())) */}
                {orcamentoAtual.map(orcamento => {
                  return (
                    <tr key={`orcamento-${orcamento.idOrcamento}`}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img
                              src={getLogo(orcamento.nomeEmpreendimento)}
                              alt={`${orcamento.tituloOrcamento}`}
                              style={{ width: '40px', height: '40px' }}
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link
                              key={`orcamento-${orcamento.idOrcamento}-link`}
                              to={`/orcamentos/${orcamento.idOrcamento}`}
                              className='text-dark fw-bold text-hover-primary fs-6'>
                              {orcamento.tituloOrcamento}
                            </Link>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {orcamento.nomeEmpreendimento}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                          {orcamento.idOrcamento}
                        </span>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-bold'>Rev. {orcamento.numeroRevisao}</span>
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-bold'>
                              {moment(orcamento.dataRevisao).format('DD/MM/YYYY')}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='me-2 fs-7 fw-bold'>
                              <CurrencyFormat
                                value={orcamento.custoTotal}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={'R$'}
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link
                            to={`/orcamentos/${orcamento.idOrcamento}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                            <span className='svg-icon svg-icon-3'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='svg-icon-muted svg-icon-1x'
                              />
                            </span>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <div className='d-flex flex-row'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                <div className='dataTables_length' id='kt_customers_table_length'>
                  <label>
                    <select
                      name='kt_customers_table_length'
                      aria-controls='kt_customers_table'
                      className='form-select form-select-sm form-select-solid'
                      value={orcamentosPorPagina}
                      onChange={e => setOrcamentosPorPagina(Number(e.target.value))}>
                      <option value={LISTA_QUANTIDADE_5}>5</option>
                      <option value={LISTA_QUANTIDADE_10}>10</option>
                      <option value={LISTA_QUANTIDADE_25}>25</option>
                      <option value={LISTA_QUANTIDADE_50}>50</option>
                      <option value={LISTA_QUANTIDADE_100}>100</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div className='dataTables_paginate paging_simple_numbers' id='kt_customers_table_paginate'>
                  <ul className='pagination'>
                    <li className='page-item previous'>
                      <button
                        disabled={paginaAtual === 0}
                        onClick={() => setPaginaAtual(paginaAnterior)}
                        className='page-link'>
                        <i className='previous' />
                      </button>
                    </li>
                    {Array.from(Array(paginas), (orcamento, index) => {
                      const pagina = index + 1
                      return (
                        <li key={`pagina-orcamento-${index}`} className='page-item'>
                          <a href={`#${index.toString()}`} className='page-link' onClick={() => setPaginaAtual(index)}>
                            {pagina}
                          </a>
                        </li>
                      )
                    })}
                    <li className='page-item next'>
                      <button
                        disabled={paginaAtual === paginas - 1}
                        onClick={() => {
                          setPaginaAtual(proximaPagina)
                        }}
                        className='page-link'>
                        <i className='next' />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export { OrcamentoListagem }
