import axios from 'axios'

import * as orcamentoHelper from '../OrcamentoHelpers'
import { OrcamentoModel } from './_models'

const API_URL_ORCAMENTOS = `${process.env.REACT_APP_API_URL_ORCAMENTO}`

export const LIST_ORCAMENTOS_URL = `${API_URL_ORCAMENTOS}/listar`

export async function list() {
  return await axios.get<Array<OrcamentoModel>>(LIST_ORCAMENTOS_URL).then(response => {
    const list = response.data as Array<OrcamentoModel>
    orcamentoHelper.setListOrcamentos(list)
    return list
  })
}
