import { Company } from './core/_models'

const COMPANY_LOCAL_STORAGE_KEY = 'kt-company-react-v'
const COMPANIES_LOCAL_STORAGE_KEY = 'kt-companies-react-v'

const initialValues: Company = {
  companyId: 0,
  companyName: '',
  orderQuantity: 0,
}

const getCompanies = (): Company[] => {
  if (!localStorage) {
    return [initialValues]
  }

  const lsValue: string | null = localStorage.getItem(COMPANIES_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return [initialValues]
  }
  try {
    const lsValue = localStorage.getItem(COMPANIES_LOCAL_STORAGE_KEY)
    if (lsValue) {
      const companies: Company[] = JSON.parse(lsValue) as Company[]
      if (companies) {
        return companies
      }
    }
  } catch (error) {
    console.error('COMPANIES LOCAL STORAGE PARSE ERROR', error)
  }

  return [initialValues]
}

const setCompanies = (companies: Company[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(companies)
    localStorage.setItem(COMPANIES_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('COMPANIES LOCAL STORAGE SAVE ERROR', error)
  }
}

const getCompany = (): Company => {
  if (!localStorage) {
    return initialValues
  }

  const lsValue: string | null = localStorage.getItem(COMPANY_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValues
  }

  try {
    const company: Company = JSON.parse(lsValue) as Company
    if (company) {
      // You can easily check company_token expiration also
      return company
    }
  } catch (error) {
    console.error('COMPANY LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValues
}

const setCompany = (company: Company | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(company)
    localStorage.setItem(COMPANY_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('COMPANY LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeCompany = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(COMPANY_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('COMPANY LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {
  getCompany,
  setCompany,
  removeCompany,
  setCompanies,
  getCompanies,
  initialValues,
  COMPANY_LOCAL_STORAGE_KEY,
  COMPANIES_LOCAL_STORAGE_KEY,
}
