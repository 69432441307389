import { FC, useState, useEffect, createContext, useContext, Dispatch, SetStateAction, useCallback } from 'react'

import { WithChildren } from '../../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import {
  EmpreendimentoDTO,
  convertTo,
  getAll,
  list as empreendimentosList,
  setList as setEmpreendimentosList,
} from '../../../modules/empreendimentos'
import { ServicoModel, getInitialServices, setInitial, initialListState as initialServico } from '../../servicos'
import { OrcamentoModel } from './core/_models'
import { list } from './core/_requests'
import * as helpers from './OrcamentoHelpers'

type OrcamentoContextProps = {
  servico: ServicoModel
  orcamentos: OrcamentoModel[] | []
  currentOrcamento: OrcamentoModel | undefined
  saveOrcamento: (orcamento: OrcamentoModel | undefined) => void
  setServico: (servico: ServicoModel) => void
  setCurrentOrcamento: Dispatch<SetStateAction<OrcamentoModel | undefined>>
}

const initOrcamentoContextPropsState = {
  servico: initialServico,
  orcamentos: helpers.listOrcamentos(),
  currentOrcamento: helpers.getOrcamento(),
  setServico: () => {},
  saveOrcamento: () => {},
  setCurrentOrcamento: () => {},
}

const OrcamentoContext = createContext<OrcamentoContextProps>(initOrcamentoContextPropsState)

const useOrcamento = () => {
  return useContext(OrcamentoContext)
}

const OrcamentoProvider: FC<WithChildren> = ({ children }) => {
  const [orcamentos] = useState<Array<OrcamentoModel>>(helpers.listOrcamentos())
  const [currentOrcamento, setCurrentOrcamento] = useState<OrcamentoModel | undefined>()
  const [servico, setServico] = useState<ServicoModel>(initialServico)

  const saveOrcamento = (orcamento: OrcamentoModel | undefined) => {
    setCurrentOrcamento(orcamento)
    if (orcamento) {
      helpers.setOrcamento(orcamento)
    }
  }

  useEffect(() => {}, [orcamentos, servico])

  return (
    <OrcamentoContext.Provider
      value={{ orcamentos, saveOrcamento, currentOrcamento, setCurrentOrcamento, servico, setServico }}>
      {children}
    </OrcamentoContext.Provider>
  )
}

const OrcamentoInit: FC<WithChildren> = ({ children }) => {
  const { setServico, servico } = useOrcamento()
  const [orcamentos] = useState<Array<OrcamentoModel>>(helpers.listOrcamentos())
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const listServicos = useCallback(async () => {
    if (servico.macroGrupos?.length === 0) {
      await getInitialServices().then(response => {
        const list = response as ServicoModel
        setInitial(list)
        setServico(list)
      })
    }
  }, [servico, setServico])

  const listing = async () => {
    try {
      const listaEmpreendimentos = empreendimentosList()

      await list().then(response => {
        const list = response as Array<OrcamentoModel>
        helpers.setListOrcamentos(list)
      })

      if (listaEmpreendimentos[0].idEmpreendimento === 0) {
        await getAll().then(results => {
          const list = results as EmpreendimentoDTO[]
          setEmpreendimentosList(
            list.map(empreendimento => {
              return convertTo(empreendimento)
            }),
          )
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setShowSplashScreen(false)
    }
    return helpers.listOrcamentos()
  }

  useEffect(() => {
    listing()
  }, [orcamentos])

  useEffect(() => {
    listServicos()
  }, [listServicos, servico])

  return showSplashScreen ? <LayoutSplashScreen visible={showSplashScreen} /> : <>{children}</>
}

export { OrcamentoInit, useOrcamento, OrcamentoProvider }
