import { FC, useCallback } from 'react'

import { useOrcamentoDetalhe } from '../../../modules/orcamentos/details'
import { currencyFormat, currencyToFloat } from '../../../modules/orcamentos/details/core/_models'
import { ComposicaoInsumoServico, Servico } from '../../../modules/servicos/core/_models'

type ComposicaoInsumoSaveProps = {
  servicoView: Servico
}

const ComposicaoInsumoSave: FC<ComposicaoInsumoSaveProps> = ({ servicoView }) => {
  const { orcamento, saveOrcamento } = useOrcamentoDetalhe()
  const servicos = servicoView.composicao?.COMPOSICAO as ComposicaoInsumoServico[]

  const saveInsumoComposicao = useCallback(
    (composicoes: ComposicaoInsumoServico[]) => {
      const servicoViewDto = { ...servicoView }

      servicoViewDto.composicao.VALOR_SERVICO = composicoes.reduce((acc, composicao) => {
        const valor = composicao.valor_material * composicao.qtd_material
        return acc + valor
      }, 0)

      servicoViewDto.composicao!.COMPOSICAO = composicoes

      saveOrcamento(servicoViewDto)
    },
    [saveOrcamento, servicoView],
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, data: ComposicaoInsumoServico) => {
      const insumo = { ...data }
      const { name, value } = e.target
      const currency = currencyToFloat(value)

      if (!isNaN(currency)) {
        const result = servicos.map(composicao => {
          if (composicao.id_pkservico === insumo.id_pkservico) {
            return name === 'quantidade'
              ? { ...composicao, qtd_material: currency }
              : { ...composicao, valor_material: currency }
          }
          return composicao
        })

        saveInsumoComposicao(result)

        // return result
        // })
      }
    },
    [saveInsumoComposicao, servicos],
  )

  return (
    <div className='table table-responsive-lg'>
      <table className='table table-striped gy-7 gs-7'>
        <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
            <th className='min-w-20px'>Código</th>
            <th className='min-w-500px'>Insumo</th>
            <th className='min-w-30px'>Vl.unitário</th>
            <th className='min-w-30px'>Quantidade</th>
          </tr>
        </thead>
        <tbody>
          {servicos.map(insumo => (
            <tr key={insumo.id_material} className='bg-hover-light-primary'>
              <td>{insumo.id_material}</td>
              <td>{insumo.nome_material}</td>
              <td>
                <div className='input-group mb-5'>
                  <span className='input-group-text'>R$</span>
                  <input
                    type='text'
                    className='form-control'
                    id={`precoUnitario_${insumo.id_material}`}
                    value={insumo.valor_material}
                    aria-label='Preço unitário do valor do material'
                    disabled={orcamento.flagFechado === 1}
                    onChange={e => handleChange(e, insumo)}
                    name='precoUnitario'
                  />
                </div>
              </td>
              <td>
                <div className='input-group mb-5'>
                  <input
                    type='text'
                    className='form-control'
                    id={`quantidade_${insumo.id_material}`}
                    value={insumo.qtd_material}
                    onChange={e => handleChange(e, insumo)}
                    disabled={orcamento.flagFechado === 1}
                    name='quantidade'
                  />
                  <span className='input-group-text'>{insumo.siglaUnidadeServico}</span>
                </div>
              </td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { ComposicaoInsumoSave }
