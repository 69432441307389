import { FC } from 'react'

import { PageTitle } from '../../../_metronic/layout/core'
import { OrcamentoInit } from '../../modules/orcamentos/list/Orcamento'
import { OrcamentoListagem } from './OrcamentoListagem'

const OrcamentoWrapper: FC = () => {
  return (
    <>
      <OrcamentoInit>
        <PageTitle breadcrumbs={[]}>Orçamentos</PageTitle>
        <OrcamentoListagem />
      </OrcamentoInit>
    </>
  )
}

export default OrcamentoWrapper
