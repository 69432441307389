import { Grupo, MacroGrupo, Servico, SubGrupo, initialValuesServiceModel } from '../../servicos'
import { OrcamentoDetalheModel } from './core/_models'

const ORCAMENTO_DETALHE_LOCAL_STORAGE_KEY = 'kt-orcamento-detalhe-react-detalhe-v'
const LIST_ORCAMENTO_DETALHE_LOCAL_STORAGE_KEY = 'kt-list-orcamento-detalhe-react-detalhe-v'

const initialValues: OrcamentoDetalheModel = {
  idRevisao: 0,
  idOrcamento: 0,
  dataRevisao: '',
  flagFechado: 0,
  idEmpreendimento: 0,
  nomeEmpreendimento: '',
  liberacaoRequisicao: 0,
  tituloOrcamento: '',
  custoTotal: 0,
  observacoes: '',
  dataOcorrencia: '',
  porcentagemTolerancia: 0,
  numeroRevisao: 0,
  historico: [],
  revisoes: [],
  servicos: initialValuesServiceModel,
  servicosDTO: [],
}

const listOrcamentoDetalhes = (): OrcamentoDetalheModel[] => {
  if (!localStorage) {
    return [initialValues]
  }

  const lsValue: string | null = localStorage.getItem(LIST_ORCAMENTO_DETALHE_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return [initialValues]
  }
  try {
    const orcamentos: OrcamentoDetalheModel[] = JSON.parse(lsValue) as OrcamentoDetalheModel[]
    if (orcamentos) {
      return orcamentos
    }
  } catch (error) {
    console.error('ORCAMENTO LIST LOCAL STORAGE PARSE ERROR', error)
  }

  return [initialValues]
}

const setListOrcamentoDetalhes = (list: OrcamentoDetalheModel[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(list)
    localStorage.setItem(LIST_ORCAMENTO_DETALHE_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('ORCAMENTO LIST LOCAL STORAGE SAVE ERROR', error)
  }
}

const getOrcamentoDetalhe = (): OrcamentoDetalheModel => {
  if (!localStorage) {
    return initialValues
  }

  const lsValue: string | null = localStorage.getItem(ORCAMENTO_DETALHE_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValues
  }

  try {
    const orcamento: OrcamentoDetalheModel = JSON.parse(lsValue) as OrcamentoDetalheModel
    if (orcamento) {
      // You can easily check orcamento_token expiration also
      return orcamento
    }
  } catch (error) {
    console.error('ORCAMENTO LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValues
}

const setOrcamentoDetalhe = (orcamento: OrcamentoDetalheModel | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(orcamento)
    localStorage.setItem(ORCAMENTO_DETALHE_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('ORCAMENTO DETALHE LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeOrcamentoDetalhe = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(ORCAMENTO_DETALHE_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('ORCAMENTO DETALHE LOCAL STORAGE REMOVE ERROR', error)
  }
}

const filterServicoGroup = <T extends MacroGrupo | Grupo | SubGrupo | Servico>(
  group: MacroGrupo[] | Grupo[] | SubGrupo[] | Servico[],
  type: 'macrogrupo' | 'grupo' | 'subgrupo' | 'servico',
  idMacroGrupo = 0,
  idGrupo = 0,
  idSubGrupo = 0,
): T[] => {
  switch (type) {
    case 'macrogrupo': {
      const macrogrupos = group as MacroGrupo[]
      return macrogrupos.filter(macrogrupo => macrogrupo.idMacroGrupo === idMacroGrupo) as T[]
    }
    case 'grupo': {
      const grupos = group as Grupo[]
      return grupos.filter(grupo => grupo.idMacroGrupo === idMacroGrupo) as T[]
    }
    case 'subgrupo': {
      const subgrupos = group as SubGrupo[]
      return subgrupos.filter(subgrupo => subgrupo.idPkGrupo === idGrupo) as T[]
    }
    case 'servico': {
      const servicos = group as SubGrupo[]
      return servicos.filter(
        servico =>
          servico.idSubGrupo === idSubGrupo && servico.idMacroGrupo === idMacroGrupo && servico.idPkGrupo === idGrupo,
      ) as T[]
    }
  }
}

export {
  getOrcamentoDetalhe,
  setOrcamentoDetalhe,
  removeOrcamentoDetalhe,
  setListOrcamentoDetalhes,
  listOrcamentoDetalhes,
  filterServicoGroup,
  initialValues,
  ORCAMENTO_DETALHE_LOCAL_STORAGE_KEY,
  LIST_ORCAMENTO_DETALHE_LOCAL_STORAGE_KEY,
}
