import { Purchaser } from './core/_models'

const PURCHASER_LOCAL_STORAGE_KEY = 'kt-purchaser-react-v'
const PURCHASERS_LOCAL_STORAGE_KEY = 'kt-purchasers-react-v'

const initialValues: Purchaser = {
  purchaserId: 0,
  purchaserName: '',
}

const getPurchasers = (): Purchaser[] => {
  if (!localStorage) {
    return [initialValues]
  }

  const lsValue: string | null = localStorage.getItem(PURCHASERS_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return [initialValues]
  }
  try {
    const lsValue = localStorage.getItem(PURCHASERS_LOCAL_STORAGE_KEY)
    if (lsValue) {
      const purchaser: Purchaser[] = JSON.parse(lsValue) as Purchaser[]
      if (purchaser) {
        return purchaser
      }
    }
  } catch (error) {
    console.error('PURCHASERS LOCAL STORAGE PARSE ERROR', error)
  }

  return [initialValues]
}

const setPurchasers = (purchasers: Purchaser[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(purchasers)
    localStorage.setItem(PURCHASERS_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('PURCHASER LOCAL STORAGE SAVE ERROR', error)
  }
}

const getPurchaser = (): Purchaser => {
  if (!localStorage) {
    return initialValues
  }

  const lsValue: string | null = localStorage.getItem(PURCHASER_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValues
  }

  try {
    const purchaser: Purchaser = JSON.parse(lsValue) as Purchaser
    if (purchaser) {
      // You can easily check purchaser_token expiration also
      return purchaser
    }
  } catch (error) {
    console.error('PURCHASER LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValues
}

const setPurchaser = (purchaser: Purchaser | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(purchaser)
    localStorage.setItem(PURCHASER_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('PURCHASER LOCAL STORAGE SAVE ERROR', error)
  }
}

const removePurchaser = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(PURCHASER_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('PURCHASER LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {
  getPurchaser,
  setPurchaser,
  removePurchaser,
  setPurchasers,
  getPurchasers,
  initialValues,
  PURCHASER_LOCAL_STORAGE_KEY,
  PURCHASERS_LOCAL_STORAGE_KEY,
}
