import { FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { OrcamentoDetalheProvider } from '../modules/orcamentos/details'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import OrcamentoDetalheWrapper from '../pages/detalhe/OrcamentoDetalheWrapper'
import OrcamentoWrapper from '../pages/orcamentos/OrcamentoWrapper'
import ServicoWrapper from '../pages/servicos/ServicoWrapper'
import { OrderKpiProvider } from '../modules/orderkpi'
import OrderKpiWrapper from '../pages/orderkpi/OrderKpiWrapper'
import { useAuth } from '../modules/auth'

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

const PrivateRoutes = () => {
  const { initialRoute } = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={initialRoute} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='/orcamentos'
          element={
            <SuspensedView>
              <OrcamentoWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='/orcamentos/:idOrcamento'
          element={
            <SuspensedView>
              <OrcamentoDetalheProvider>
                <OrcamentoDetalheWrapper />
              </OrcamentoDetalheProvider>
            </SuspensedView>
          }
        />
        `
        <Route
          path='/pedidos'
          element={
            <SuspensedView>
              <OrderKpiProvider>
                <OrderKpiWrapper />
              </OrderKpiProvider>
            </SuspensedView>
          }
        />
        `
        <Route
          path='/servicos'
          element={
            <SuspensedView>
              <ServicoWrapper />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
