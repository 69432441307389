import { Building } from '../../../modules/building'

export const initialBuildings: Building[] = [
  {
    buildingId: 77,
    buildingName: 'Arken',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 71,
    buildingName: 'Autêntico Residencial',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 76,
    buildingName: 'AYA ',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 720,
    buildingName: 'CD - \tUni Bosque Maia',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 770,
    buildingName: 'CD - Arken',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 710,
    buildingName: 'CD - Autêntico',
    companyId: 150,
    tradeName: 'JJO - CD',
  },
  {
    buildingId: 740,
    buildingName: 'CD - CUBE',
    companyId: 170,
    tradeName: 'JJO - Cube - CD',
  },
  {
    buildingId: 730,
    buildingName: 'CD - Due Residencial',
    companyId: 150,
    tradeName: 'JJO - CD',
  },
  {
    buildingId: 310,
    buildingName: 'CD - Forever',
    companyId: 220,
    tradeName: 'VCI Construtora - CD',
  },
  {
    buildingId: 760,
    buildingName: 'CD - Residencial AYA ',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 750,
    buildingName: 'CD - The Gate',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 660,
    buildingName: 'CD - Vegus',
    companyId: 1,
    tradeName: 'Vegus',
  },
  {
    buildingId: 150,
    buildingName: 'CD - Viva Vida',
    companyId: 40,
    tradeName: 'INDASE',
  },
  {
    buildingId: 12,
    buildingName: 'Champs du Bagatelle',
    companyId: 10,
    tradeName: 'Incorfast - Vegus',
  },
  {
    buildingId: 13,
    buildingName: 'Champs Elysées',
    companyId: 10,
    tradeName: 'Incorfast - Vegus',
  },
  {
    buildingId: 74,
    buildingName: 'Cube Office Guarulhos',
    companyId: 17,
    tradeName: 'JJO Cube SCP',
  },
  {
    buildingId: 78,
    buildingName: 'Dona Tecla 4',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 31,
    buildingName: 'Forever Residence Resort',
    companyId: 22,
    tradeName: 'VCI Construtora',
  },
  {
    buildingId: 10,
    buildingName: 'Ícaro Residencial',
    companyId: 6,
    tradeName: 'Incorfast',
  },
  {
    buildingId: 712,
    buildingName: 'JMGC - Autêntico Residencial',
    companyId: 2,
    tradeName: 'Gruve',
  },
  {
    buildingId: 742,
    buildingName: 'JMGC - CUBE',
    companyId: 2,
    tradeName: 'Gruve',
  },
  {
    buildingId: 732,
    buildingName: 'JMGC - Due Residencial',
    companyId: 2,
    tradeName: 'Gruve',
  },
  {
    buildingId: 312,
    buildingName: 'JMGC - Forever Residence Resort',
    companyId: 2,
    tradeName: 'Gruve',
  },
  {
    buildingId: 752,
    buildingName: 'JMGC - The Gate',
    companyId: 2,
    tradeName: 'Gruve',
  },
  {
    buildingId: 54,
    buildingName: 'Massimo Residencial',
    companyId: 14,
    tradeName: 'HU - Zaira',
  },
  {
    buildingId: 14,
    buildingName: 'New Village',
    companyId: 40,
    tradeName: 'INDASE',
  },
  {
    buildingId: 61,
    buildingName: 'Ourives',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 53,
    buildingName: 'Piazza Della Fontana',
    companyId: 5,
    tradeName: 'HU',
  },
  {
    buildingId: 73,
    buildingName: 'Residencial Due',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 16,
    buildingName: 'Supremo Residencial',
    companyId: 6,
    tradeName: 'Incorfast',
  },
  {
    buildingId: 93,
    buildingName: 'Terreno GRU - Fórum',
    companyId: 33,
    tradeName: 'GRU - Fórum SCP',
  },
  {
    buildingId: 92,
    buildingName: 'Terreno ITU',
    companyId: 31,
    tradeName: 'Vegus Construtora',
  },
  {
    buildingId: 91,
    buildingName: 'The Brick',
    companyId: 30,
    tradeName: 'The Brick SCP',
  },
  {
    buildingId: 910,
    buildingName: 'The Brick  - CD',
    companyId: 300,
    tradeName: 'Vegus Construtora - CD',
  },
  {
    buildingId: 912,
    buildingName: 'The Brick  - CD',
    companyId: 300,
    tradeName: 'Vegus Construtora - CD',
  },
  {
    buildingId: 75,
    buildingName: 'The Gate',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 51,
    buildingName: 'The Way - Granja Vianna',
    companyId: 5,
    tradeName: 'HU',
  },
  {
    buildingId: 72,
    buildingName: 'Uni Bosque Maia',
    companyId: 15,
    tradeName: 'JJO',
  },
  {
    buildingId: 66,
    buildingName: 'Vegus',
    companyId: 1,
    tradeName: 'Vegus',
  },
  {
    buildingId: 11,
    buildingName: "Villa D'Este",
    companyId: 10,
    tradeName: 'Incorfast - Vegus',
  },
  {
    buildingId: 52,
    buildingName: 'Ville Mediterranée',
    companyId: 5,
    tradeName: 'HU',
  },
  {
    buildingId: 15,
    buildingName: 'Viva Vida',
    companyId: 40,
    tradeName: 'INDASE',
  },
]
