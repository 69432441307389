import axios from 'axios'
import { toast } from 'react-toastify'

import { OrderKpi, OrderKpiRequest, requestInitialValues } from '../index'

const API_URL = process.env.REACT_APP_API_URL_ADMINCORP_PEDIDO //ADICIONAR no .env.development
const API_KPI_URL = `${API_URL}/v1/order/kpis`

export async function requestKpi(orderKpi: OrderKpiRequest = requestInitialValues) {
  return await axios
    .get<OrderKpi>(API_KPI_URL, { params: orderKpi })
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao consultar o Kpi')
    })
}
