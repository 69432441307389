import { useState, FC, useEffect } from 'react'

import { OrderByFamily } from './components/OrderByFamily'
import { OrderPurchaser } from './components/OrderPurchaser'
import { TimeByPurchaser } from './components/TimeByPurchaser'
import { ChartKpi } from './components/ChartKpi'
import { OrderSupplier } from './components/OrderSupplier'
import { OrderKpiRequest, useOrderKpi } from '../../modules/orderkpi'
import { Status } from './components/Status'
import { FilterKpi } from './components/FilterKpi'

const OrderKpi: FC = () => {
  const [reload, setReload] = useState(true)
  const { kpiRequest, orderKpi, saveRequest, sender, save } = useOrderKpi()

  const handler = (orderKpiRequest: OrderKpiRequest) => {
    if (orderKpiRequest.purchaserName === 'Todos') {
      orderKpiRequest.purchaserName = ''
    }
    if (orderKpiRequest.familyName === 'Todos') {
      orderKpiRequest.familyName = ''
    }
    if (orderKpiRequest.buildingId === '0') {
      orderKpiRequest.buildingId = ''
    }
    if (orderKpiRequest.companyId === '0') {
      orderKpiRequest.companyId = ''
    }
    saveRequest(orderKpiRequest)
    setReload(!reload)
  }

  useEffect(() => {
    if (!reload) return

    sender(kpiRequest).then(response => {
      save(response)
    })
    setReload(false)
  }, [kpiRequest, sender, save, reload, orderKpi])

  return (
    <div className='container-fluid mb-10'>
      <div className='d-flex flex-end '>
        <FilterKpi handler={handler} />
      </div>
      <div className='row g-5'>
        <div className='col-lg-4'>
          <Status className='card-xl-stretch-55' color='info' handler={handler} />
          <div className='card card-custom shadow mb-5'>
            <div className='card-header'>
              <h3 className='card-title'>Pedidos por Comprador</h3>
            </div>
            <div className='card-body'>
              <OrderPurchaser />
            </div>
          </div>

          <div className='card card-custom shadow mb-5'>
            <div className='card-header'>
              <h3 className='card-title'>Tempo de Processo por Comprador</h3>
            </div>
            <div className='card-body'>
              <TimeByPurchaser />
            </div>
          </div>
        </div>

        <div className='col-lg-8'>
          <div className='card card-custom card-stretch-50 shadow mb-5' style={{ height: '450px' }}>
            <div className='card-header'>
              <h3 className='card-title'>Pedidos por Tempo</h3>
            </div>
            <div className='card-body'>
              <ChartKpi />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='card card-custom card-stretch-33 shadow mb-5'>
                <div className='card-header'>
                  <h3 className='card-title'>Pedidos por Família</h3>
                </div>
                <div className='card-body'>
                  <OrderByFamily />
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='card card-custom card-stretch-33 shadow mb-5'>
                <div className='card-header'>
                  <h3 className='card-title'>Pedidos por Fornecedor</h3>
                </div>
                <div className='card-body card-scroll'>
                  <OrderSupplier />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { OrderKpi }
