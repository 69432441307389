import { FC, useEffect, useState } from 'react'

import { KTSVG } from '../../../../_metronic/helpers'
import { useOrcamentoDetalhe } from '../../../modules/orcamentos/details'
import { initialState as servicoState } from '../../../modules/orcamentos/details/reducers/reducer'
import { Grupo, Servico, ServicoViewDTO, SubGrupo } from '../../../modules/servicos'
import { ComposicaoServicoView } from './ComposicaoServicoView'
import ServicoAccordion from './ServicoAccordion'
import { HistoricoTab } from './Tabs'

const OrcamentoServicoTree: FC = () => {
  const { orcamento, saveOrcamento } = useOrcamentoDetalhe()
  const [showInsumo, setShowInsumo] = useState(false)
  const [servicoViewDto, setServicoViewDto] = useState(servicoState)

  const parseProps = (toGrupo: Grupo | Grupo[]): Grupo => {
    return Array.isArray(toGrupo) ? toGrupo[0] : toGrupo
  }
  const parsePropsSubgrupo = (toSubGrupo: SubGrupo | SubGrupo[]): SubGrupo => {
    return Array.isArray(toSubGrupo) ? toSubGrupo[0] : toSubGrupo
  }

  const showComposicao = (show: boolean, servicoDto: Servico) => {
    const servico = { ...servicoViewDto, servico: servicoDto, composicao: servicoDto.composicao } as ServicoViewDTO
    setServicoViewDto(servico)
    setShowInsumo(show)
  }
  const changeComposicao = (servico: Servico) => {
    const servicoDto = { ...servicoViewDto, servico, composicao: servico.composicao } as ServicoViewDTO
    setServicoViewDto(servicoDto)
    saveOrcamento(servico)
  }

  useEffect(() => {}, [orcamento, servicoViewDto, showInsumo])

  return (
    <div className='row g-2 mt-5'>
      <div className='col-lg-3'>
        <div className='card card-custom card-stretch mb-5 me-5'>
          <div className='card-header'>
            <h3 className='card-title'>Principal</h3>
            <div className='card-toolbar'>
              <ul className='nav nav-tabs nav-pills border-0 flex-row flex-md-row me-5 mb-3 mb-md-0 fs-7'>
                <li className='nav-item me-0'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                    Serviços
                  </a>
                </li>
                <li className='nav-item me-0'>
                  <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                    Historico
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='card-body'>
            <div className='tab-content h-750px card-scroll' id='myTabContent'>
              <div className='tab-pane fade show card-scroll active' id='kt_tab_pane_1' role='tabpanel'>
                {
                  orcamento.servicos.macroGrupos?.map(macrogrupo => {
                    return (
                      <div
                        className='accordion accordion-icon-toggle'
                        key={`macrogrupo-accordion-${macrogrupo.idMacroGrupo}`}>
                        <div className='mb-5'>
                          <div
                            className='accordion-header py-3 d-flex collapsed text-hover-warning'
                            data-bs-toggle='collapse'
                            data-bs-target={`#macrogrupo_${macrogrupo.idMacroGrupo}_grupo_${
                              parseProps(macrogrupo.grupos).idGrupo
                            }`}>
                            <span className='accordion-icon'>
                              <KTSVG className='svg-icon svg-icon-4' path='/media/icons/duotune/arrows/arr064.svg' />
                            </span>
                            <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                              <strong className='stepper-title'>{macrogrupo.description}</strong>
                            </h3>
                          </div>
                          <div
                            id={`macrogrupo_${macrogrupo.idMacroGrupo}_grupo_${parseProps(macrogrupo.grupos).idGrupo}`}
                            className='fs-6 collapse ps-10'
                            data-bs-parent={`#macrogrupo_${macrogrupo.idMacroGrupo}`}>
                            <div className='mb-5'>
                              {macrogrupo.grupos.map(grupo => (
                                <div key={`macrogrupo-accordion-${macrogrupo.idMacroGrupo}-grupo-${grupo.idPkGrupo}`}>
                                  <div
                                    className='accordion-header py-3 d-flex collapsed text-hover-warning'
                                    data-bs-toggle='collapse'
                                    data-bs-target={`#macrogrupo_${macrogrupo.idMacroGrupo}_grupo_${
                                      grupo.idGrupo
                                    }_subgrupo_${parsePropsSubgrupo(grupo.subgrupos)?.idSubGrupo}`}>
                                    <span className='accordion-icon'>
                                      <KTSVG
                                        className='svg-icon svg-icon-4'
                                        path='/media/icons/duotune/arrows/arr064.svg'
                                      />
                                    </span>
                                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                                      <strong className='stepper-title'>{grupo.description}</strong>
                                    </h3>
                                  </div>
                                  <div
                                    id={`macrogrupo_${macrogrupo.idMacroGrupo}_grupo_${grupo.idGrupo}_subgrupo_${
                                      parsePropsSubgrupo(grupo.subgrupos)?.idSubGrupo
                                    }`}
                                    className='fs-6 collapse ps-10'
                                    data-bs-parent={`#macrogrupo_${macrogrupo.idMacroGrupo}`}>
                                    <div className='mb-5'>
                                      {grupo.subgrupos.map(subgrupo => (
                                        <div
                                          key={`macrogrupo-accordion-${macrogrupo.idMacroGrupo}-grupo-${grupo.idPkGrupo}-subgrupo-${subgrupo.idPkSubGrupo}`}>
                                          <div
                                            className='accordion-header py-3 d-flex collapsed text-hover-warning'
                                            data-bs-toggle='collapse'
                                            data-bs-target={`#macrogrupo_${subgrupo.idMacroGrupo}_grupo_${subgrupo.idGrupo}_subgrupo_${subgrupo.idSubGrupo}_servicos_${subgrupo.idSubGrupo}`}>
                                            <span className='accordion-icon'>
                                              <KTSVG
                                                className='svg-icon svg-icon-4'
                                                path='/media/icons/duotune/arrows/arr064.svg'
                                              />
                                            </span>
                                            <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                                              <strong className='stepper-title'>{subgrupo.description}</strong>
                                            </h3>
                                          </div>
                                          <div
                                            id={`macrogrupo_${subgrupo.idMacroGrupo}_grupo_${subgrupo.idGrupo}_subgrupo_${subgrupo.idSubGrupo}_servicos_${subgrupo.idSubGrupo}`}
                                            className='fs-6 collapse ps-10'
                                            data-bs-parent={`#macrogrupo_${subgrupo.idMacroGrupo}_grupo_${subgrupo.idGrupo}_subgrupo_${subgrupo.idSubGrupo}`}>
                                            <div className='mb-5'>
                                              <ServicoAccordion
                                                services={subgrupo.servicos}
                                                showComposicao={showComposicao}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  // )
                }
              </div>
              <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
                {<HistoricoTab historicos={orcamento.historico} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInsumo && <ComposicaoServicoView changeComposicao={changeComposicao} servicoView={servicoViewDto.servico} />}
    </div>
  )
}

export default OrcamentoServicoTree
