import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Empreendimento } from './core/_models'

const EMPREENDIMENTOS_LOCAL_STORAGE_KEY = 'kt-empreendimentos-react-v'
const LIST_EMPREENDIMENTOS_LOCAL_STORAGE_KEY = 'kt-empreendimentos-react-v'

const initialValues: Empreendimento = {
  idEmpreendimento: 0,
  nomeEmpreendimento: '',
  idEmpresa: 0,
  nomeFantasia: '',
}

const initialListValues: Empreendimento[] = [
  {
    idEmpreendimento: 77,
    nomeEmpreendimento: 'Arken',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 71,
    nomeEmpreendimento: 'Autêntico Residencial',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 76,
    nomeEmpreendimento: 'AYA ',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 720,
    nomeEmpreendimento: 'CD - \tUni Bosque Maia',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 770,
    nomeEmpreendimento: 'CD - Arken',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 710,
    nomeEmpreendimento: 'CD - Autêntico',
    idEmpresa: 150,
    nomeFantasia: 'JJO - CD',
  },
  {
    idEmpreendimento: 740,
    nomeEmpreendimento: 'CD - CUBE',
    idEmpresa: 170,
    nomeFantasia: 'JJO - Cube - CD',
  },
  {
    idEmpreendimento: 730,
    nomeEmpreendimento: 'CD - Due Residencial',
    idEmpresa: 150,
    nomeFantasia: 'JJO - CD',
  },
  {
    idEmpreendimento: 310,
    nomeEmpreendimento: 'CD - Forever',
    idEmpresa: 220,
    nomeFantasia: 'VCI Construtora - CD',
  },
  {
    idEmpreendimento: 760,
    nomeEmpreendimento: 'CD - Residencial AYA ',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 750,
    nomeEmpreendimento: 'CD - The Gate',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 660,
    nomeEmpreendimento: 'CD - Vegus',
    idEmpresa: 1,
    nomeFantasia: 'Vegus',
  },
  {
    idEmpreendimento: 150,
    nomeEmpreendimento: 'CD - Viva Vida',
    idEmpresa: 40,
    nomeFantasia: 'INDASE',
  },
  {
    idEmpreendimento: 12,
    nomeEmpreendimento: 'Champs du Bagatelle',
    idEmpresa: 10,
    nomeFantasia: 'Incorfast - Vegus',
  },
  {
    idEmpreendimento: 13,
    nomeEmpreendimento: 'Champs Elysées',
    idEmpresa: 10,
    nomeFantasia: 'Incorfast - Vegus',
  },
  {
    idEmpreendimento: 74,
    nomeEmpreendimento: 'Cube Office Guarulhos',
    idEmpresa: 17,
    nomeFantasia: 'JJO Cube SCP',
  },
  {
    idEmpreendimento: 94,
    nomeEmpreendimento: 'Dona Antonia',
    idEmpresa: 31,
    nomeFantasia: 'Dona Antonia',
  },
  {
    idEmpreendimento: 78,
    nomeEmpreendimento: 'Dona Tecla 4',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 31,
    nomeEmpreendimento: 'Forever Residence Resort',
    idEmpresa: 22,
    nomeFantasia: 'VCI Construtora',
  },
  {
    idEmpreendimento: 10,
    nomeEmpreendimento: 'Ícaro Residencial',
    idEmpresa: 6,
    nomeFantasia: 'Incorfast',
  },
  {
    idEmpreendimento: 712,
    nomeEmpreendimento: 'JMGC - Autêntico Residencial',
    idEmpresa: 2,
    nomeFantasia: 'Gruve',
  },
  {
    idEmpreendimento: 742,
    nomeEmpreendimento: 'JMGC - CUBE',
    idEmpresa: 2,
    nomeFantasia: 'Gruve',
  },
  {
    idEmpreendimento: 732,
    nomeEmpreendimento: 'JMGC - Due Residencial',
    idEmpresa: 2,
    nomeFantasia: 'Gruve',
  },
  {
    idEmpreendimento: 312,
    nomeEmpreendimento: 'JMGC - Forever Residence Resort',
    idEmpresa: 2,
    nomeFantasia: 'Gruve',
  },
  {
    idEmpreendimento: 752,
    nomeEmpreendimento: 'JMGC - The Gate',
    idEmpresa: 2,
    nomeFantasia: 'Gruve',
  },
  {
    idEmpreendimento: 54,
    nomeEmpreendimento: 'Massimo Residencial',
    idEmpresa: 14,
    nomeFantasia: 'HU - Zaira',
  },
  {
    idEmpreendimento: 14,
    nomeEmpreendimento: 'New Village',
    idEmpresa: 40,
    nomeFantasia: 'INDASE',
  },
  {
    idEmpreendimento: 61,
    nomeEmpreendimento: 'Ourives',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 53,
    nomeEmpreendimento: 'Piazza Della Fontana',
    idEmpresa: 5,
    nomeFantasia: 'HU',
  },
  {
    idEmpreendimento: 73,
    nomeEmpreendimento: 'Residencial Due',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 16,
    nomeEmpreendimento: 'Supremo Residencial',
    idEmpresa: 6,
    nomeFantasia: 'Incorfast',
  },
  {
    idEmpreendimento: 93,
    nomeEmpreendimento: 'Terreno GRU - Fórum',
    idEmpresa: 33,
    nomeFantasia: 'GRU - Fórum SCP',
  },
  {
    idEmpreendimento: 92,
    nomeEmpreendimento: 'Terreno ITU',
    idEmpresa: 31,
    nomeFantasia: 'Vegus Construtora',
  },
  {
    idEmpreendimento: 91,
    nomeEmpreendimento: 'The Brick',
    idEmpresa: 30,
    nomeFantasia: 'The Brick SCP',
  },
  {
    idEmpreendimento: 910,
    nomeEmpreendimento: 'The Brick  - CD',
    idEmpresa: 300,
    nomeFantasia: 'Vegus Construtora - CD',
  },
  {
    idEmpreendimento: 912,
    nomeEmpreendimento: 'The Brick  - CD',
    idEmpresa: 300,
    nomeFantasia: 'Vegus Construtora - CD',
  },
  {
    idEmpreendimento: 75,
    nomeEmpreendimento: 'The Gate',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 51,
    nomeEmpreendimento: 'The Way - Granja Vianna',
    idEmpresa: 5,
    nomeFantasia: 'HU',
  },
  {
    idEmpreendimento: 72,
    nomeEmpreendimento: 'Uni Bosque Maia',
    idEmpresa: 15,
    nomeFantasia: 'JJO',
  },
  {
    idEmpreendimento: 66,
    nomeEmpreendimento: 'Vegus',
    idEmpresa: 1,
    nomeFantasia: 'Vegus',
  },
  {
    idEmpreendimento: 11,
    nomeEmpreendimento: "Villa D'Este",
    idEmpresa: 10,
    nomeFantasia: 'Incorfast - Vegus',
  },
  {
    idEmpreendimento: 52,
    nomeEmpreendimento: 'Ville Mediterranée',
    idEmpresa: 5,
    nomeFantasia: 'HU',
  },
  {
    idEmpreendimento: 15,
    nomeEmpreendimento: 'Viva Vida',
    idEmpresa: 40,
    nomeFantasia: 'INDASE',
  },
]

const getLogo = (nomeEmpreendimento: string) => {
  switch (nomeEmpreendimento.split(' ')[0].toLowerCase()) {
    case 'aya':
      return toAbsoluteUrl('/media/logos/aya.png')
    case 'arken':
      return toAbsoluteUrl('/media/logos/arken.png')
    case 'cube':
      return toAbsoluteUrl('/media/logos/cube.png')
    case 'uni':
      return toAbsoluteUrl('/media/logos/due.png')
    case 'forever':
      return toAbsoluteUrl('/media/logos/forever.png')
    case 'brick':
      return toAbsoluteUrl('/media/logos/thebrick.png')
    case 'gate':
      return toAbsoluteUrl('/media/logos/thegate.png')
    default:
      return toAbsoluteUrl('/media/logos/admincorp_logo.png')
  }
}
const get = (): Empreendimento => {
  if (!localStorage) {
    return initialValues
  }

  const lsValue: string | null = localStorage.getItem(EMPREENDIMENTOS_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValues
  }

  try {
    const empreendimento: Empreendimento = JSON.parse(lsValue) as Empreendimento
    if (empreendimento) {
      // You can easily check EMPREENDIMENTO_token expiration also
      return empreendimento
    }
  } catch (error) {
    console.error('EMPREENDIMENTO LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValues
}

const set = (empreendimento: Empreendimento | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(empreendimento)
    localStorage.setItem(EMPREENDIMENTOS_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('EMPREENDIMENTO LOCAL STORAGE SAVE ERROR', error)
  }
}

const remove = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(EMPREENDIMENTOS_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('EMPREENDIMENTO LOCAL STORAGE REMOVE ERROR', error)
  }
}

const list = (): Empreendimento[] => {
  if (!localStorage) {
    return [initialValues]
  }

  const lsValue: string | null = localStorage.getItem(LIST_EMPREENDIMENTOS_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return [initialValues]
  }
  try {
    const empreendimentos: Empreendimento[] = JSON.parse(lsValue) as Empreendimento[]
    if (empreendimentos) {
      // You can easily check EMPREENDIMENTO_token expiration also
      return empreendimentos
    }
  } catch (error) {
    console.error('EMPREENDIMENTOS LIST LOCAL STORAGE PARSE ERROR', error)
  }

  return [initialValues]
}

const setList = (list: Empreendimento[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(list)
    localStorage.setItem(LIST_EMPREENDIMENTOS_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('EMPREENDIMENTOS LIST LOCAL STORAGE SAVE ERROR', error)
  }
}

export { get, set, remove, list, setList, getLogo, initialValues, initialListValues, EMPREENDIMENTOS_LOCAL_STORAGE_KEY }
