import { FC, useState, useEffect, createContext, useContext } from 'react'

import { WithChildren } from '../../../_metronic/helpers'
import { Family, requestFamily, setFamilies } from '../family/index'
import { Building, requestBuildings, setBuildings } from '../building/index'
import { LayoutSplashScreen } from '../../../_metronic/layout/core'
import { Company, setCompanies, requestCompany } from '../company'
import { Purchaser, requestPurchaser, setPurchasers } from '../purchaser'
import { OrderKpi, OrderKpiRequest, initialValues, requestKpi, setOrderKpi, requestInitialValues } from './index'

interface OrderKpiContextProps {
  orderKpi: OrderKpi
  families: Family[]
  kpiRequest: OrderKpiRequest
  save: (orderKpi: OrderKpi) => void
  saveFamilies: (families: Family[]) => void
  buildings: Building[]
  saveBuildings: (buildings: Building[]) => void
  companies: Company[]
  saveCompanies: (companies: Company[]) => void
  purchasers: Purchaser[]
  saveRequest: (kpiRequest: OrderKpiRequest) => void
  savePurchasers: (purchaser: Purchaser[]) => void
  sender: (request: OrderKpiRequest) => Promise<OrderKpi>
}

const initOrderKpiContextPropsState: OrderKpiContextProps = {
  orderKpi: {} as OrderKpi,
  families: [],
  buildings: [],
  companies: [],
  purchasers: [],
  kpiRequest: {} as OrderKpiRequest,
  save: () => {},
  saveFamilies: () => {},
  saveCompanies: () => {},
  saveBuildings: () => {},
  savePurchasers: () => {},
  saveRequest: () => {},
  sender: () => {
    return {} as Promise<OrderKpi>
  },
}

const OrderKpiContext = createContext<OrderKpiContextProps>(initOrderKpiContextPropsState)

const useOrderKpi = () => {
  return useContext(OrderKpiContext)
}

const OrderKpiProvider: FC<WithChildren> = ({ children }) => {
  const [orderKpi, setStateOrderKpi] = useState<OrderKpi>(initialValues)
  const [kpiRequest, setKpiRequest] = useState<OrderKpiRequest>(requestInitialValues)
  const [families, setFamiliesOrderKpi] = useState<Array<Family>>([])
  const [companies, setPedidoCompanies] = useState<Array<Company>>([])
  const [buildings, setPedidoBuildings] = useState<Array<Building>>([])
  const [purchasers, setPedidoPurchasers] = useState<Array<Purchaser>>([])

  const saveBuildings = (buildings: Building[]) => {
    setPedidoBuildings(buildings)
    setBuildings(buildings)
  }

  const savePurchasers = (purchasers: Purchaser[]) => {
    setPedidoPurchasers(purchasers)
    setPurchasers(purchasers)
  }

  const saveCompanies = (companies: Company[]) => {
    setPedidoCompanies(companies)
    setCompanies(companies)
  }

  const saveFamilies = (families: Family[]) => {
    setFamiliesOrderKpi(() => {
      return families
    })
    setFamilies(families)
  }

  const save = (orderKpi: OrderKpi) => {
    setStateOrderKpi(orderKpi)
    setOrderKpi(orderKpi)
  }

  const saveRequest = (kpiRequest: OrderKpiRequest) => {
    setKpiRequest(kpiRequest)
  }

  const sender = async (request: OrderKpiRequest): Promise<OrderKpi> => {
    const response = await requestKpi(request)
    return response as OrderKpi
  }

  return (
    <OrderKpiContext.Provider
      value={{
        orderKpi,
        buildings,
        companies,
        purchasers,
        kpiRequest,
        families,
        save,
        sender,
        saveFamilies,
        saveBuildings,
        saveCompanies,
        saveRequest,
        savePurchasers,
      }}>
      {children}
    </OrderKpiContext.Provider>
  )
}

const OrderKpiInit: FC<WithChildren> = ({ children }) => {
  const { save, savePurchasers, saveFamilies, saveBuildings, saveCompanies } = useOrderKpi()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const caller = async () => {
    try {
      await requestKpi().then(result => {
        save(result as OrderKpi)
      })
      await requestPurchaser().then(result => {
        const purchasers = [{ purchaserName: 'Todos', purchaserId: 0 }]
        purchasers.push(...(result as Purchaser[]))
        savePurchasers(purchasers)
      })
      await requestFamily().then(result => {
        const families = [{ familyName: 'Todos', familyId: 0 }]
        families.push(...(result as Family[]))
        saveFamilies(families)
      })
      await requestCompany().then(result => {
        const companies = [{ companyName: 'Todos', companyId: 0 }]
        companies.push(...(result as Company[]))
        saveCompanies(companies)
      })
      await requestBuildings().then(result => {
        const buildings = [{ buildingName: 'Todos', buildingId: 0, companyId: 0, tradeName: '' }]
        buildings.push(...(result as Building[]))
        saveBuildings(buildings)
      })
    } catch (error) {
      console.error(error)
    } finally {
      setShowSplashScreen(false)
    }
  }

  useEffect(() => {
    caller()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { OrderKpiInit, useOrderKpi, OrderKpiProvider, OrderKpiContext }
