import { initialBuildings } from '../../utils/data/json/buildings'
import { Building, EmpreendimentoDTO } from './core/_models'

const BUILDING_LOCAL_STORAGE_KEY = 'kt-building-react-v'
const BUILDINGS_LOCAL_STORAGE_KEY = 'kt-buildings-react-v'

const initialValues: Building = {
  buildingId: 0,
  buildingName: '',
  companyId: 0,
  tradeName: '',
}

const initialListValues: Building[] = initialBuildings

const getBuilding = (): Building => {
  if (!localStorage) {
    return initialValues
  }

  const lsValue: string | null = localStorage.getItem(BUILDING_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValues
  }

  try {
    const building: Building = JSON.parse(lsValue) as Building
    if (building) {
      return building
    }
  } catch (error) {
    console.error('BUILDING LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValues
}

const setBuilding = (building: Building | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(building)
    localStorage.setItem(BUILDING_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('BUILDING LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeBuilding = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(BUILDING_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('BUILDING LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getBuildings = (): Building[] => {
  if (!localStorage) {
    return [initialValues]
  }

  const lsValue: string | null = localStorage.getItem(BUILDINGS_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return [initialValues]
  }
  try {
    const buildings: Building[] = JSON.parse(lsValue) as Building[]
    if (buildings) {
      return buildings
    }
  } catch (error) {
    console.error('BUILDINGS LOCAL STORAGE PARSE ERROR', error)
  }

  return [initialValues]
}

const setBuildings = (buildings: Building[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(buildings)
    localStorage.setItem(BUILDINGS_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('BUILDINGS LOCAL STORAGE SAVE ERROR', error)
  }
}

export {
  getBuilding,
  setBuilding,
  removeBuilding,
  getBuildings,
  setBuildings,
  initialValues,
  initialListValues,
  BUILDING_LOCAL_STORAGE_KEY,
}

export function convertTo(building: EmpreendimentoDTO): Building {
  return {
    buildingId: building.id_empreendimento,
    buildingName: building.nome_empreendimento,
    companyId: building.id_empresa,
    tradeName: building.nome_fantasia,
  } as Building
}

export function convertFrom(empreendimentos: EmpreendimentoDTO[]): Building[] {
  return empreendimentos.map(empreendimento => {
    return convertTo(empreendimento)
  })
}
