import { StepProps } from './ModelosDeServico'

const Step1 = ({ data, updateData, hasError }: StepProps) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Nome do Macrogrupo</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Digite o nome do seu Macrogrupo'
            />
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='macrogrupo'
            placeholder=''
            value={data.appBasic.appName}
            onChange={e =>
              updateData({
                appBasic: {
                  appName: e.target.value,
                  appType: data.appBasic.appType,
                },
              })
            }
          />
          {!data.appBasic.appName && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                Nome do Macrogrupo é Obrigatório
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export { Step1 }
