import React, { useCallback, useEffect, useRef } from 'react'
import ApexCharts from 'apexcharts'
import moment from 'moment'

import { getCSS } from '../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { KpiOrderTime, getChartOptions, useOrderKpi } from '../../../modules/orderkpi'

const ChartKpi: React.FC = () => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const { orderKpi } = useOrderKpi()

  // Add a 'date' field to each KpiOrderTime object
  const dataWithDate = orderKpi.orderTime.map((kpiOrderTime: KpiOrderTime) => ({
    ...kpiOrderTime,
    date: moment(`${kpiOrderTime.year}-${kpiOrderTime.month}-01`),
  }))

  // Filter data for the last 3 years
  const currentDate = moment()
  const threeYearsAgo = currentDate.clone().subtract(3, 'years')
  const filteredData = dataWithDate.filter((data: any) => {
    return data.date.isBetween(threeYearsAgo, currentDate, 'month', '[]')
  })

  // Sort filtered data by date
  const sortedData = filteredData.sort((a: any, b: any) => a.date - b.date)

  const dataChart = sortedData.map((data: any) => {
    return data.amount
  })

  const monthChart = sortedData.map((data: any) => {
    return data.date.format('MMM/YYYY')
  })

  const refreshChart = useCallback(() => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, dataChart, monthChart))
    if (chart) {
      chart.render()
    }

    return chart
  }, [dataChart, monthChart])

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [dataChart, dataChart, chartRef, mode, refreshChart])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
    </div>
  )
}

export { ChartKpi }
