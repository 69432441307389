/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import { useLayout } from '../../../_metronic/layout/core'
import { CreateAppModal } from './steps/CreateAppModal'

const Servico: React.FC = () => {
  const { config } = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  return (
    <>
      <div className='d-flex align-items-center mb-10'>
        {config.app?.toolbar?.primaryButton && (
          <a
            href='#'
            onClick={() => setShowCreateAppModal(true)}
            className='btn btn-sm fw-bold btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_app'>
            Criar Novo Serviço
          </a>
        )}
        <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
      </div>
    </>
  )
}
export { Servico }
