import axios from 'axios'
import { toast } from 'react-toastify'

import { Building } from './_models'

const API_URL_PEDIDOS = process.env.REACT_APP_API_URL_ADMINCORP_PEDIDO
const BUILDINGS_API_URL = `${API_URL_PEDIDOS}/v1/building/list`

interface ResponseBuilding {
  results: {
    response: any
    errorCode: number
    detail: string
  }
}
export async function requestBuildings() {
  return await axios
    .get<Array<Building>>(BUILDINGS_API_URL)
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao listar os empreendimentos')
    })
}
