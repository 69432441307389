import { FC, useState, useEffect, createContext, useContext, useCallback } from 'react'

import { WithChildren } from '../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../_metronic/layout/core'
import { requestPurchaser } from './core/_requests'
import { Purchaser, getPurchasers, getPurchaser, setPurchaser, initialValues } from './index'

interface PurchaserContextProps {
  purchaser: Purchaser
  purchasers: Purchaser[]
  save: (purchaser: Purchaser) => void
  savePurchaser: (purchasers: Purchaser[]) => void
}

const initPurchaserContextPropsState = {
  purchasers: getPurchasers(),
  state: getPurchaser(),
  purchaser: getPurchaser(),
  save: () => {},
  savePurchaser: () => {},
}

const PurchaserContext = createContext<PurchaserContextProps>(initPurchaserContextPropsState)

const usePurchaser = () => {
  return useContext(PurchaserContext)
}

export interface Action {
  type: string
  payload: Purchaser
}

const PurchaserProvider: FC<WithChildren> = ({ children }) => {
  const [purchaser, setPurchaserState] = useState<Purchaser>(getPurchaser())
  const [purchasers, setPurchasers] = useState<Purchaser[]>(getPurchasers())

  const save = (purchaser: Purchaser) => {
    setPurchaserState(purchaser)
    setPurchaser(purchaser)
  }

  const savePurchaser = (purchasers: Purchaser[]) => {
    setPurchasers(purchasers)
    setPurchasers(purchasers)
  }

  useEffect(() => {}, [purchaser])

  useEffect(() => {
    return () => {
      setPurchaser(initialValues)
      save(initialValues)
    }
  }, [])

  return (
    <PurchaserContext.Provider
      value={{
        purchaser,
        purchasers,
        save,
        savePurchaser,
      }}>
      {children}
    </PurchaserContext.Provider>
  )
}

const PurchaserInit: FC<WithChildren> = ({ children }) => {
  const { savePurchaser } = usePurchaser()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const purchaserRequest = useCallback(async () => {
    try {
      await requestPurchaser().then(result => {
        savePurchaser(result as Purchaser[])
      })
    } catch (error) {
      console.error(error)
    } finally {
      setShowSplashScreen(false)
    }
  }, [savePurchaser])

  useEffect(() => {
    purchaserRequest()
  }, [purchaserRequest])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { PurchaserInit, usePurchaser, PurchaserProvider, PurchaserContext }
