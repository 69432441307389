import { FC, ChangeEvent, useEffect } from 'react'
import CurrencyFormat from 'react-currency-format'

import { toInt, useOrcamentoDetalhe } from '../../../modules/orcamentos/details'
import { Servico } from '../../../modules/servicos'
import ActionsNavigatorOrcamento from './ActionsNavigatorOrcamento'
import { ComposicaoInsumoSave } from './ComposicaoInsumoSave'
import { ComposicaoView } from './ComposicaoView'

interface IComposicaoServicoViewProps {
  servicoView: Servico
  changeComposicao: (servico: Servico) => void
}

const ComposicaoServicoView: FC<IComposicaoServicoViewProps> = ({ servicoView, changeComposicao }) => {
  const { orcamento } = useOrcamentoDetalhe()
  const handleQuantidade = (event: ChangeEvent<HTMLInputElement>) => {
    const value = { ...servicoView, composicao: { ...servicoView.composicao, QTD_SERVICO: toInt(event.target.value) } }
    changeComposicao(value)
  }

  useEffect(() => {}, [servicoView])

  return (
    <div className='col-lg-9'>
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>{servicoView.description || ''}</h3>
        </div>
        <div className='card-body'>
          <div className='card card-custom'>
            <div className='card-header'>
              <div className='card-toolbar'>
                <ul className='nav nav-tabs nav-pills border-0 flex-row flex-md-row me-5 mb-3 mb-md-0 fs-7'>
                  <li className='nav-item me-0'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
                      Revisão Anterior
                    </a>
                  </li>

                  <li className='nav-item me-0'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
                      Revisão Atual
                    </a>
                  </li>
                  <li className='nav-item me-0'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
                      Base de Serviços
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='card-body'>
              <div className='card card-custom d-flex flex-column mb-2 p-5'>
                <div className='d-flex flex-row p-5 m-1 bg-hover-light-primary text-hover-primary rounded p-2'>
                  <strong className='center ms-1 mt-2 mb-2 me-20'>Valor Serviço:</strong>
                  <span className='text-warning ms-3 mt-2 mb-2 w-75'>
                    <CurrencyFormat
                      value={servicoView.composicao.VALOR_SERVICO}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={orcamento.flagFechado === 1}
                      prefix={'R$'}
                    />
                  </span>
                </div>
                <div className='d-flex flex-row p-5 m-1 w-100 bg-hover-light-primary text-hover-primary rounded p-2'>
                  <strong className='mt-2 mb-2 me-2'>Quantidade do Serviço:</strong>
                  <input
                    title='Quantidade de serviços'
                    className='form-control w-100px h-30px ms-3'
                    value={servicoView.composicao.QTD_SERVICO}
                    disabled={orcamento.flagFechado === 1}
                    onChange={e => handleQuantidade(e)}
                  />
                  <span className='text-warning w-75 ms-3 mt-1'>{servicoView.composicao.SIGLAUNIDADE}</span>
                </div>
                <div className='d-flex flex-row p-5 m-1 bg-hover-light-primary text-hover-primary rounded p-2'>
                  <strong className='mt-2 mb-2 me-3'>Valor Total do Serviço:</strong>
                  <span className='text-warning ms-5 mt-2 mb-2 w-75'>
                    <CurrencyFormat
                      value={servicoView.composicao.QTD_SERVICO * servicoView.composicao.VALOR_SERVICO}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'R$'}
                    />
                  </span>
                </div>
              </div>
              <div className='tab-content' id='tabComposicaoInsumos'>
                <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
                  {servicoView.composicao?.COMPANTERIOR && (
                    <ComposicaoView composicao={servicoView.composicao.COMPANTERIOR.COMPOSICAO} />
                  )}
                </div>
                <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
                  <div className='d-flex flex-stack container-fluid mb-5'>
                    {/* <a href='#'>Substituir pela composição da revisão anterior</a>
        <a href='#'>Substituir pela composição da base de serviço</a> */}
                  </div>
                  <ComposicaoInsumoSave servicoView={servicoView} />
                </div>
                <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
                  {servicoView.composicao?.COMPATUALIZADA && (
                    <ComposicaoView composicao={servicoView.composicao.COMPATUALIZADA.COMPOSICAO} />
                  )}
                </div>
              </div>
              <div className='card-footer d-flex flex-stack container-fluid'>
                <h5>Total: </h5>
                <h5>
                  <CurrencyFormat
                    value={servicoView.composicao.VALOR_SERVICO}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'R$'}
                  />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='card mt-5'>
        <div className='card-body text-end'>
          <ActionsNavigatorOrcamento />
        </div>
      </div> */}
    </div>
  )
}

export { ComposicaoServicoView }
