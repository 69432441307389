/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from './ModelosDeServico'

const Step3 = ({ data, updateData, hasError }: StepProps) => {
  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}

          <div className='fv-row mb-10'>
            <label className='required fs-5 fw-semibold mb-2'>Subgrupo</label>

            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='dbname'
              value={data.appDatabase.databaseName}
              onChange={e =>
                updateData({
                  appDatabase: {
                    databaseName: e.target.value,
                    databaseSolution: data.appDatabase.databaseSolution,
                  },
                })
              }
            />
            {!data.appDatabase.databaseName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  O nome do Subgrupo é Obrigatório
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}
        </div>
      </div>
    </>
  )
}

export { Step3 }
