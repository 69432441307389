import { ChangeEvent, FC } from 'react'

interface CalendarProps {
  date: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const Calendar: FC<CalendarProps> = ({ date, onChange }) => {
  return (
    <div className='d-flex flex-row'>
      <input
        type='date'
        className='form-select form-select-solid'
        style={{
          backgroundImage: 'none',
        }}
        data-placeholder='Escolha a data'
        placeholder=''
        value={date}
        onChange={e => onChange(e)}
      />
    </div>
  )
}

export { Calendar }
