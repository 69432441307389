import React, { FC, useState, useCallback, useMemo } from 'react'

import { useOrcamentoDetalhe } from '../../../modules/orcamentos/details'
import { Servico } from '../../../modules/servicos/core/_models'

type ServicoProps = {
  services: Servico[]
  showComposicao: (show: boolean, servico: Servico) => void
}

// TODO: Refatorar este componente para que ele possa ser reutilizado e diminuir a complexidade

const ServicoAccordion: FC<ServicoProps> = ({ services, showComposicao }) => {
  const { saveOrcamento } = useOrcamentoDetalhe()
  const [servicos, setServicos] = useState<Servico[]>(services)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, data: Servico) => {
      const checked = e.target.checked
      const servico = { ...data, checked }
      saveOrcamento(servico)

      if (checked) {
        setServicos(prevServicos =>
          prevServicos.map(filtered => {
            return filtered.codServico === servico.codServico ? { ...filtered, checked } : filtered
          }),
        )
        showComposicao(checked, servico)

        return
      }

      setServicos(prevServicos => {
        return prevServicos.map(filtered => {
          if (filtered.codServico === servico.codServico) {
            return { ...filtered, checked: false }
          }
          return filtered
        })
      })
      showComposicao(checked, servico)
    },
    [saveOrcamento, showComposicao],
  )

  return (
    <>
      {useMemo(() => {
        return servicos.map(servico => (
          <div key={`servico-accordion-item-${servico.idServico}`} className='mb-5'>
            <div className='form-check form-check-custom form-check-success form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                value={servico.idServico}
                id={`macrogrupo_${servico.idMacroGrupo}_grupo_${servico.idGrupo}_subgrupo_${servico.idSubGrupo}_servicos_${servico.idServico}_checkbox`}
                checked={servicos.some(selected => {
                  return selected.idServico === servico.idServico && selected.checked
                })}
                onChange={e => {
                  handleChange(e, servico)
                }}
              />
              <label
                className='form-check-label bg-hover-light-success text-hover-success w-100 h-30px rounded p-2'
                onClick={() => {
                  showComposicao(true, servico)
                }}>
                {servico.description}
              </label>
            </div>
          </div>
        ))
      }, [servicos, handleChange, showComposicao])}
    </>
  )
}

export default ServicoAccordion
