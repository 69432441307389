import { FC } from 'react'

import { useOrderKpi } from '../../../modules/orderkpi'

// Função para formatar o valor como horas ou dias e horas
const formatTime = (timeInHours: number) => {
  if (timeInHours < 24) {
    return `${timeInHours} horas` // Exibe apenas horas se for menos de um dia
  } else {
    const days = Math.floor(timeInHours / 24)
    const remainingHours = timeInHours % 24
    return `${days} dias ${remainingHours} horas` // Exibe dias e horas para mais de um dia
  }
}

const TimeByPurchaser: FC = () => {
  const { orderKpi } = useOrderKpi()

  // Realize a ordenação aqui
  const sortedAverageTimePurchaser = [...orderKpi.averageTimePurchaser].sort((a, b) =>
    a.purchaserName.localeCompare(b.purchaserName),
  )

  return (
    <div className='table-responsive' style={{ overflowX: 'auto', maxHeight: '400px' }}>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead style={{ position: 'sticky', top: '0', zIndex: 1 }}>
          <tr className='fw-bold fs-4 text-muted border-bottom-2 border-gray-200 bg-gray-100'>
            <th className='px-5'>Comprador</th>
            <th className='px-5'>Média de Tempo por Processo</th>
          </tr>
        </thead>
        <tbody>
          {sortedAverageTimePurchaser.map((purchaser, index) => (
            <tr key={purchaser.purchaserId}>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200 px-5'>
                      {purchaser.purchaserName}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex flex-column w-100 me-2 align-items-center'>
                  <div className='d-flex flex-stack mb-2'>
                    <span className='text-muted me-2 fs-7 fw-semibold'>{formatTime(purchaser.averageProcessTime)}</span>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export { TimeByPurchaser }
