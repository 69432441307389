import { FC, useState, useEffect, createContext, useContext, useCallback } from 'react'

import { WithChildren } from '../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../_metronic/layout/core'
import { Family } from './core/_models'
import { requestFamily } from './core/_requests'
import * as FamilyHelpers from './FamilyHelpers'

interface FamilyContextProps {
  family: Family
  families: Family[]
  save: (family: Family) => void
  saveFamilies: (families: Family[]) => void
}

const initFamilyContextPropsState = {
  families: FamilyHelpers.getFamilies(),
  state: FamilyHelpers.getFamily(),
  family: FamilyHelpers.getFamily(),
  save: () => {},
  saveFamilies: () => {},
}

const FamilyContext = createContext<FamilyContextProps>(initFamilyContextPropsState)

const useFamily = () => {
  return useContext(FamilyContext)
}

const FamilyProvider: FC<WithChildren> = ({ children }) => {
  const [family, setFamily] = useState<Family>(FamilyHelpers.getFamily())
  const [families, setFamilies] = useState<Family[]>(FamilyHelpers.getFamilies)

  const save = (family: Family) => {
    setFamily(family)
    FamilyHelpers.setFamily()
  }

  const saveFamilies = (families: Family[]) => {
    setFamilies(families)
    FamilyHelpers.setFamilies(families)
  }

  useEffect(() => {}, [family])

  useEffect(() => {
    return () => {
      setFamily(FamilyHelpers.initialValues)
      save(FamilyHelpers.initialValues)
    }
  }, [])

  return (
    <FamilyContext.Provider
      value={{
        family,
        families,
        save,
        saveFamilies,
      }}>
      {children}
    </FamilyContext.Provider>
  )
}

const FamilyInit: FC<WithChildren> = ({ children }) => {
  const { saveFamilies } = useFamily()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const familyRequest = useCallback(async () => {
    try {
      await requestFamily().then(result => {
        saveFamilies(result as Family[])
      })
    } catch (error) {
      console.error(error)
    } finally {
      setShowSplashScreen(false)
    }
  }, [saveFamilies])

  useEffect(() => {
    familyRequest()
  }, [familyRequest])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { FamilyInit, useFamily, FamilyProvider, FamilyContext }
