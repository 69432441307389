import { ComposicaoDTO, OrcamentoDTO, OrcamentoDetalheModel, ServicoDTO } from '../details'
import { OrcamentoModel } from './core/_models'

const ORCAMENTO_LIST_LOCAL_STORAGE_KEY = 'kt-orcamento-react-list-v'
const ORCAMENTO_LOCAL_STORAGE_KEY = 'kt-orcamento-react-v'

const initialValues: OrcamentoModel = {
  dataRevisao: '',
  flagFechado: 0,
  idEmpreendimento: 0,
  idRevisao: 0,
  idOrcamento: 0,
  nomeEmpreendimento: '',
  liberacaoRequisicao: 0,
  tituloOrcamento: '',
  custoTotal: 0,
  dataOcorrencia: '',
  porcentagemTolerancia: 0,
  numeroRevisao: 0,
  revisoes: [],
  historico: [],
  servicos: [],
}

const buildDTO = (orcamento: OrcamentoDetalheModel): OrcamentoDTO => {
  const dto = { ...orcamento }
  const servicoCompDTO: ServicoDTO[] = []

  if (dto.idOrcamento !== 0) {
    dto.servicos.macroGrupos?.forEach(macroGrupo =>
      macroGrupo?.grupos?.forEach(grupo =>
        grupo?.subgrupos?.forEach(subgrupo =>
          subgrupo?.servicos?.forEach(servico => {
            if (!servico.checked) {
              return
            }
            const composicaoCompDTO: ComposicaoDTO[] = []
            servico.composicao?.COMPOSICAO.forEach(element => {
              composicaoCompDTO.push(element)
            })

            const servicosDTO: ServicoDTO = {
              tolerancia: 0,
              id_servico: servico.idServico,
              idunidadeservico: servico.idUnidade,
              quantidade: servico.composicao!.QTD_SERVICO,
              cod_servico: servico.codServico,
              idpkservico: servico.idPkServico,
              valor_unitario: servico.composicao!.VALOR_SERVICO,
              observacao: '',
              composicao: composicaoCompDTO,
            }
            servicoCompDTO.push(servicosDTO)
          }),
        ),
      ),
    )
  }

  const orcamentoDto: OrcamentoDTO = {
    id_orcamento: dto.idOrcamento,
    id_revisao: dto.idRevisao,
    id_empreendimento: dto.idEmpreendimento,
    numero_revisao: dto.numeroRevisao,
    titulo_orcamento: dto.tituloOrcamento,
    data_ocorrencia: dto.dataOcorrencia,
    porcentagem_tolerancia: dto.porcentagemTolerancia,
    data_revisao: dto.dataRevisao,
    liberacao_requisicao: dto.liberacaoRequisicao,
    observacoes: dto.observacoes,
    flag_fechado: dto.flagFechado,

    servicos: servicoCompDTO,
  }

  return orcamentoDto
}

const listOrcamentos = (): OrcamentoModel[] => {
  if (!localStorage) {
    return [initialValues]
  }

  const lsValue: string | null = localStorage.getItem(ORCAMENTO_LIST_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return [initialValues]
  }
  try {
    const orcamentos: OrcamentoModel[] = JSON.parse(lsValue) as OrcamentoModel[]
    if (orcamentos) {
      // You can easily check orcamento_token expiration also
      return orcamentos
    }
  } catch (error) {
    console.error('ORCAMENTO LIST LOCAL STORAGE PARSE ERROR', error)
  }

  return [initialValues]
}

const setListOrcamentos = (list: OrcamentoModel[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(list)
    localStorage.setItem(ORCAMENTO_LIST_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('ORCAMENTO LIST LOCAL STORAGE SAVE ERROR', error)
  }
}

const getOrcamento = (): OrcamentoModel => {
  if (!localStorage) {
    return initialValues
  }

  const lsValue: string | null = localStorage.getItem(ORCAMENTO_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValues
  }

  try {
    const orcamento: OrcamentoModel = JSON.parse(lsValue) as OrcamentoModel
    if (orcamento) {
      // You can easily check orcamento_token expiration also
      return orcamento
    }
  } catch (error) {
    console.error('ORCAMENTO LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValues
}

const setOrcamento = (orcamento: OrcamentoModel | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(orcamento)
    localStorage.setItem(ORCAMENTO_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('ORCAMENTO LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeOrcamento = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(ORCAMENTO_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('ORCAMENTO LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {
  listOrcamentos,
  setListOrcamentos,
  getOrcamento,
  setOrcamento,
  removeOrcamento,
  buildDTO,
  ORCAMENTO_LOCAL_STORAGE_KEY,
}
