import { FC } from 'react'

import { OrderKpiRequest, useOrderKpi } from '../../../modules/orderkpi'
import { Building } from '../../../modules/building'

interface ComboBoxBuildingProps {
  buildings: Building[]
  handler: (orderKpiRequest: OrderKpiRequest) => void
}

const ComboBoxBuilding: FC<ComboBoxBuildingProps> = ({ buildings, handler }) => {
  const { kpiRequest } = useOrderKpi()

  const onChange = (event: any) => {
    handler({ ...kpiRequest, buildingId: event.target.value })
  }

  return (
    <select
      className='form-select form-select-solid'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      placeholder='Obra'
      onChange={onChange}>
      {buildings.map(building => (
        <option key={building.buildingId} value={building.buildingId}>
          {building.buildingName}
        </option>
      ))}
    </select>
  )
}

export { ComboBoxBuilding }
