import { FC, useState, useEffect, createContext, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { WithChildren } from '../../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import * as Empreendimentos from '../../empreendimentos/'
import { Servico } from '../../servicos/core/_models'
import { Historico, OrcamentoDetalheModel } from './core/_models'
import { getBy } from './core/_requests'
import * as OrcamentoDetalheHelpers from './OrcamentoDetalheHelpers'

interface OrcamentoDetalheContextProps {
  orcamento: OrcamentoDetalheModel
  historico: Historico[] | []
  empreendimentos: Empreendimentos.Empreendimento[] | []
  save: (orcamento: OrcamentoDetalheModel) => void
  saveOrcamento: (byServico: Servico) => void
  saveEmpreendimentos: (empreendimentos: Empreendimentos.Empreendimento[]) => void
}

const initOrcamentoContextPropsState = {
  state: OrcamentoDetalheHelpers.getOrcamentoDetalhe(),
  orcamento: OrcamentoDetalheHelpers.getOrcamentoDetalhe(),
  historico: [],
  empreendimentos: [],
  save: () => {},
  saveOrcamento: () => {},
  saveEmpreendimentos: () => {},
}

const OrcamentoDetalheContext = createContext<OrcamentoDetalheContextProps>(initOrcamentoContextPropsState)

const useOrcamentoDetalhe = () => {
  return useContext(OrcamentoDetalheContext)
}

export interface Action {
  type: string
  payload: OrcamentoDetalheModel
}

const OrcamentoDetalheProvider: FC<WithChildren> = ({ children }) => {
  const [orcamento, setOrcamento] = useState<OrcamentoDetalheModel>(OrcamentoDetalheHelpers.getOrcamentoDetalhe())
  const [historico] = useState<Historico[]>([])
  const [empreendimentos, setEmpreendimentos] = useState<Empreendimentos.Empreendimento[]>(Empreendimentos.list())

  const save = (orcamento: OrcamentoDetalheModel) => {
    setOrcamento(orcamento)
    OrcamentoDetalheHelpers.setOrcamentoDetalhe(orcamento)
  }

  const saveOrcamento = (byServico: Servico) => {
    setOrcamento(prevOrcamento => {
      const orcamento = {
        ...prevOrcamento,
        servicos: {
          ...prevOrcamento.servicos,
          macroGrupos: prevOrcamento.servicos.macroGrupos?.map(macroGrupo => ({
            ...macroGrupo,
            grupos: macroGrupo?.grupos?.map(grupo => ({
              ...grupo,
              subgrupos: grupo?.subgrupos?.map(subgrupo => ({
                ...subgrupo,
                servicos: subgrupo?.servicos?.map(servico => {
                  return servico.codServico === byServico.codServico ? byServico : servico
                }),
              })),
            })),
          })),
        },
      }
      OrcamentoDetalheHelpers.setOrcamentoDetalhe(orcamento)
      return orcamento
    })
  }

  useEffect(() => {}, [orcamento])

  const saveEmpreendimentos = (empreendimentos: Empreendimentos.Empreendimento[]) => {
    setEmpreendimentos(empreendimentos)
    if (empreendimentos != null) {
      saveEmpreendimentos(empreendimentos)
    }
  }

  useEffect(() => {
    return () => {
      setOrcamento(OrcamentoDetalheHelpers.initialValues)
      save(OrcamentoDetalheHelpers.initialValues)
    }
  }, [])

  return (
    <OrcamentoDetalheContext.Provider
      value={{
        orcamento,
        historico,
        empreendimentos,
        save,
        saveOrcamento,
        saveEmpreendimentos,
      }}>
      {children}
    </OrcamentoDetalheContext.Provider>
  )
}

const OrcamentoDetalheInit: FC<WithChildren> = ({ children }) => {
  const { orcamento, save } = useOrcamentoDetalhe()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { idOrcamento } = useParams()

  const orcamentoDetalheRequest = useCallback(
    async (idOrcamento: any) => {
      try {
        await getBy(idOrcamento, 'last').then(result => {
          save(result as OrcamentoDetalheModel)
        })
      } catch (error) {
        console.error(error)
      } finally {
        setShowSplashScreen(false)
      }
    },
    [save],
  )

  useEffect(() => {
    const orcamentoParam = Number(idOrcamento)
    if (orcamento.idOrcamento !== orcamentoParam) {
      orcamentoDetalheRequest(idOrcamento)
    }
    // if (orcamento.idOrcamento === 0) {
    //   console.log(JSON.stringify(orcamento))
    //   setShowSplashScreen(false)
    // }
  }, [idOrcamento, orcamento, orcamentoDetalheRequest])

  return showSplashScreen ? <LayoutSplashScreen visible={showSplashScreen} /> : <>{children}</>
}

export { OrcamentoDetalheInit, useOrcamentoDetalhe, OrcamentoDetalheProvider, OrcamentoDetalheContext }
