export interface IAppBasic {
  appName: string
  appType: 'Quick Online Courses' | 'Face to Face Discussions' | 'Full Intro Training'
}

export interface IDateBasic {
  dateName: string
  dateType: '' | '' | ''
}

export type TAppFramework = 'HTML5' | 'ReactJS' | 'Angular' | 'Vue'

export type TDateFramework = 'Teste0' | 'Teste1' | 'Teste2' | 'Teste3'

export interface IAppDatabase {
  databaseName: string
  databaseSolution: 'MySQL' | 'Firebase' | 'DynamoDB'
}

export interface IDateDatabase {
  databaseName: string
  databaseSolution: 'Teste4' | 'Teste5' | 'Teste6'
}

export type TAppStorage = 'Basic Server' | 'AWS' | 'Google'

export type TDateStorage = 'Teste7' | 'Teste8' | 'Teste9'

export interface ICreateAppData {
  appBasic: IAppBasic
  appFramework: TAppFramework
  appDatabase: IAppDatabase
  appStorage: TAppStorage
  dateBasic: IDateBasic
  dateFramework: TDateFramework
  dateDatabase: IDateDatabase
  dateStorage: TDateStorage
}

export const defaultCreateAppData: ICreateAppData = {
  appBasic: { appName: '', appType: 'Quick Online Courses' },
  appFramework: 'HTML5',
  appDatabase: { databaseName: '', databaseSolution: 'MySQL' },
  appStorage: 'Basic Server',
  dateBasic: { dateName: '', dateType: '' },
  dateFramework: 'Teste0',
  dateDatabase: { databaseName: 'db_name', databaseSolution: 'Teste4' },
  dateStorage: 'Teste7',
}

export type StepProps = {
  data: ICreateAppData
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void
  hasError: boolean
}
