import axios from 'axios'
import { toast } from 'react-toastify'

import { OrcamentoDTO, OrcamentoDetalheModel, OrcamentoResponse } from './_models'

const API_URL_ORCAMENTOS = `${process.env.REACT_APP_API_URL_ORCAMENTO}`
export const URL_ORCAMENTOS_IMPRESSAO = process.env.REACT_APP_URL_ORCAMENTOS_IMPRESSAO

interface IResponse {
  results: {
    response: any
    errorCode: number
    detail: string
  }
}

export async function getBy(idOrcamento: number, revisao: any, message?: boolean) {
  return await axios
    .get<OrcamentoDetalheModel>(`${API_URL_ORCAMENTOS}/${idOrcamento}/revisao/${revisao}`)
    .then(result => {
      if (message) {
        toast.success(`Orçamento ${result.data.tituloOrcamento} carregado com sucesso!`)
      }
      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao consultar o orçamento')
    })
}

export async function get(orcamento: OrcamentoDTO, last = false) {
  const revisao = last ? 'last' : orcamento.id_revisao

  return await getBy(orcamento.id_orcamento, revisao)
}

export async function create(orcamento: OrcamentoDTO) {
  return await axios
    .post<OrcamentoResponse>(`${API_URL_ORCAMENTOS}/create`, { orcamento })
    .then(result => {
      toast.success(`Orçamento ${orcamento.titulo_orcamento} criado com sucesso!`)
      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao criar o orçamento')
    })
}

export async function save(orcamento: OrcamentoDTO) {
  return await axios
    .put<OrcamentoDTO>(`${API_URL_ORCAMENTOS}/save`, { orcamento })
    .then(result => {
      toast.success(`Orçamento ${orcamento.titulo_orcamento} salvo com sucesso!`)

      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao salvar o orçamento')
    })
}
export async function close(orcamento: OrcamentoDTO) {
  return await axios
    .put<OrcamentoDetalheModel>(`${API_URL_ORCAMENTOS}/close`, { orcamento })
    .then(result => {
      // console.log('%c 👨‍🎓: close -> result ', 'font-size:16px;background-color:#5f7ae2;color:white;', result)

      toast.success(`Orçamento ${orcamento.titulo_orcamento} fechado com sucesso!`)

      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao fechar o orçamento')
    })
}
export async function release(orcamento: OrcamentoDTO) {
  return await axios
    .put<OrcamentoDetalheModel>(`${API_URL_ORCAMENTOS}/release`, { orcamento })
    .then(result => {
      toast.success(`Orçamento ${orcamento.titulo_orcamento} liberado para requisição com sucesso!`)

      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao liberar para requisição o orçamento')
    })
}

export async function revision(orcamento: OrcamentoDTO) {
  return await axios
    .post<OrcamentoDetalheModel>(`${API_URL_ORCAMENTOS}/${orcamento.id_orcamento}/revision`)
    .then(result => {
      toast.success(`A revisão do orçamento ${orcamento.titulo_orcamento} criada com sucesso!`)

      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao criar a revisão do orçamento')
    })
}

export async function remove(orcamento: OrcamentoDTO) {
  return await axios
    .delete<IResponse>(`${API_URL_ORCAMENTOS}/${orcamento.id_orcamento}/revision`)
    .then(results => {
      if (results.data.results.errorCode !== 200) {
        toast.error(results.data.results.detail)
        return results.data
      }
      toast.success(`Orçamento ${orcamento.titulo_orcamento} excluído com sucesso!`)
      return results.data
    })
    .catch(function (error) {
      toast.error(`Erro ao excluir o orçamento ${orcamento.titulo_orcamento}`)

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data)
        console.error(error.response.status)
        console.error(error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message)
      }
      console.error(error.config)
    })
}
