import { FC } from 'react'

import { OrderKpiRequest, useOrderKpi } from '../../../modules/orderkpi'
import { Purchaser } from '../../../modules/purchaser'

interface ComboBoxPurchaserProps {
  purchasers: Purchaser[]
  handler: (orderKpiRequest: OrderKpiRequest) => void
}

const ComboBoxPurchaser: FC<ComboBoxPurchaserProps> = ({ purchasers, handler }) => {
  const { kpiRequest } = useOrderKpi()

  const onSearch = (value: string) => {}

  const onChange = (value: any) => {
    handler({ ...kpiRequest, purchaserName: value.target.value })
  }

  return (
    <select
      className='form-select form-select-solid'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      placeholder='Comprador'
      onChange={onChange}>
      {purchasers.map(purchaser => (
        <option key={purchaser.purchaserId} value={purchaser.purchaserName}>
          {purchaser.purchaserName}
        </option>
      ))}
    </select>
  )
}

export { ComboBoxPurchaser }
