import { ComposicaoInsumo, Servico, ServicoModel } from './core/_models'

const CURRENT_SERVICO_LOCAL_STORAGE_KEY = 'kt-current-servico-react-v'
const SERVICO_LIST_LOCAL_STORAGE_KEY = 'kt-servico-react-list-v'
const SERVICO_LOCAL_STORAGE_KEY = 'kt-servico-react-v'
const SERVICO_INITIAL_LOCAL_STORAGE_KEY = 'kt-servico-initial-react-v'

const initialValuesServiceModel: ServicoModel = {
  name: '',
  macroGrupos: [],
}

export const initialCurrentServicoValue: Servico = {
  idPkSubGrupo: 0,
  idPkGrupo: 0,
  idSubGrupo: 0,
  idServico: 0,
  idRevisao: 0,
  nameGroup: '',
  idPkServico: 0,
  value: 0,
  checked: false,
  name: '',
  idUnidade: 0,
  codServico: '',
  idGrupo: 0,
  siglaUnidade: '',
  idOrcamento: 0,
  description: '',
  idMacroGrupo: 0,
  composicao: {} as ComposicaoInsumo,
}

const serviceList = (): ServicoModel[] => {
  if (!localStorage) {
    return [initialValuesServiceModel]
  }

  const lsValue: string | null = localStorage.getItem(SERVICO_LIST_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return [initialValuesServiceModel]
  }
  try {
    const servicos: ServicoModel[] = JSON.parse(lsValue) as ServicoModel[]
    if (servicos) {
      // You can easily check SERVICO_token expiration also
      return servicos
    }
  } catch (error) {
    console.error('SERVICOS LIST LOCAL STORAGE PARSE ERROR', error)
  }

  return [initialValuesServiceModel]
}

const setList = (list: ServicoModel[]) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(list)
    localStorage.setItem(SERVICO_LIST_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('SERVICOS LIST LOCAL STORAGE SAVE ERROR', error)
  }
}

const get = (): ServicoModel => {
  if (!localStorage) {
    return initialValuesServiceModel
  }

  const lsValue: string | null = localStorage.getItem(SERVICO_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValuesServiceModel
  }

  try {
    const servico: ServicoModel = JSON.parse(lsValue) as ServicoModel
    if (servico) {
      // You can easily check SERVICO_token expiration also
      return servico
    }
  } catch (error) {
    console.error('SERVICO LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValuesServiceModel
}

const set = (servico: ServicoModel | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(servico)
    localStorage.setItem(SERVICO_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('SERVICO LOCAL STORAGE SAVE ERROR', error)
  }
}

const remove = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(SERVICO_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('SERVICO LOCAL STORAGE REMOVE ERROR', error)
  }
}

const initial = (): ServicoModel => {
  if (!localStorage) {
    return initialValuesServiceModel
  }

  const lsValue: string | null = localStorage.getItem(SERVICO_INITIAL_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValuesServiceModel
  }

  try {
    const servico: ServicoModel = JSON.parse(lsValue) as ServicoModel
    if (servico) {
      // You can easily check SERVICO_token expiration also
      return servico
    }
  } catch (error) {
    console.error('SERVICO LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValuesServiceModel
}

const setInitial = (servico: ServicoModel | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(servico)
    localStorage.setItem(SERVICO_INITIAL_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('SERVICO LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeInitial = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(SERVICO_INITIAL_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('SERVICO LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getCurrent = (): Servico => {
  if (!localStorage) {
    return initialCurrentServicoValue
  }

  const lsValue: string | null = localStorage.getItem(CURRENT_SERVICO_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialCurrentServicoValue
  }

  try {
    const servico: Servico = JSON.parse(lsValue) as Servico
    if (servico) {
      // You can easily check SERVICO_token expiration also
      return servico
    }
  } catch (error) {
    console.error('SERVICO LOCAL STORAGE PARSE ERROR', error)
  }

  return initialCurrentServicoValue
}

const setCurrent = (servico: Servico | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(servico)
    localStorage.setItem(CURRENT_SERVICO_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('SERVICO LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeCurrent = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(CURRENT_SERVICO_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('SERVICO LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {
  serviceList,
  setList,
  get,
  set,
  remove,
  getCurrent,
  setCurrent,
  removeCurrent,
  initial,
  setInitial,
  removeInitial,
  initialValuesServiceModel,
  CURRENT_SERVICO_LOCAL_STORAGE_KEY,
  SERVICO_LOCAL_STORAGE_KEY,
}
