/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Button from 'antd/lib/button'
import { PoweroffOutlined } from '@ant-design/icons'

import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import { initialValues as initialOrcamento, OrcamentoResponse, create } from '../../../modules/orcamentos/details'
import { buildDTO } from '../../../modules/orcamentos/list'
import { Step1 } from './steps/Step1'

type Props = {
  show: boolean
  handleClose: () => void
}
const CreateAppModal = ({ show, handleClose }: Props) => {
  const navigate = useNavigate()
  const [showCreateAppModal, setShowCreateAppModal] = useState(false)

  const [orcamento, setOrcamento] = useState(initialOrcamento)
  const [loadings, setLoadings] = useState<boolean[]>([])

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const handleCreate = async () => {
    try {
      await create(buildDTO(orcamento)).then(response => {
        const dto = response as OrcamentoResponse
        setTimeout(() => {
          navigate(`/orcamentos/${dto.response.idOrcamento}`)
        }, 3000)
      })
    } catch (error) {
      console.error(error)
    }
  }
  const enterLoading = (index: number) => {
    setLoadings(prevLoadings => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = true
      return newLoadings
    })
    setShowCreateAppModal(true)
    handleCreate()
    setTimeout(() => {
      setLoadings(prevLoadings => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = false
        return newLoadings
      })
    }, 6000)
  }
  const submit = () => {
    // if (!stepper.current) {
    //   return
    // }
    // if (stepper.current.getCurrentStepIndex() === 1) {
    //   if (!checkAppBasic()) {
    //     setHasError(true)
    //     return
    //   }
    // }
    // if (stepper.current.getCurrentStepIndex() === 2) {
    //   if (!checkAppDataBase()) {
    //     setHasError(true)
    //     return
    //   }
    // }
    // stepper.current.goNext()
    //window.location.replace('/orcamentos/0')
  }

  useEffect(() => {
    if (orcamento.dataRevisao === '') {
      setOrcamento(prev => {
        return {
          ...prev,
          dataRevisao: new Date().toISOString().split('T')[0],
          dataOcorrencia: new Date().toISOString().split('T')[0],
        }
      })
    }
  }, [orcamento, setOrcamento])
  return (
    <Modal
      id='kt_modal_create_app2'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}>
      <div className='modal-header'>
        <h2>Novo Orçamento</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'>
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check' />
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Inicio</h3>

                    <div className='stepper-desc'>Dê um nome para o Orçamento</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 1*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form'>
              <Step1 orcamento={orcamento} setOrcamento={setOrcamento} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button type='button' className='btn btn-lg btn-light-primary me-3' data-kt-stepper-action='previous'>
                    <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-3 me-1' /> Voltar
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    onClick={submit}>
                    Proximo <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
                  </button>
                  {/* <button
                    type='button'
                    className='btn btn-lg btn-light-success'
                    data-kt-stepper-action='next'
                    onClick={submit}>
                    <Link to={`/orcamentos/0`} className='text-white'>
                      <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-1 me-1' /> Salvar
                    </Link>
                  </button> */}
                  <Button
                    className='btn btn-sm fw-bold text-black-50 btn-success mb-10'
                    loading={loadings[0]}
                    onClick={() => enterLoading(0)}>
                    Salvar
                  </Button>
                  {/* <Link to={`/orcamentos/0`} className='btn btn-lg btn-light-success text-white'>
                    <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-1 me-1' /> Salvar
                  </Link> */}
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>
  )
}

export { CreateAppModal }
