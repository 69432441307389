import { ApexOptions } from 'apexcharts'
import moment from 'moment'

import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { OrderKpi, OrderKpiRequest } from './index'

const KPI_LOCAL_STORAGE_KEY = 'kt-kpi-react-v'

const initialValues: OrderKpi = {
  orderSituation: [],
  orderPurchaser: [],
  orderFamily: [],
  orderTime: [],
  orderSupplier: [],
  averageTimePurchaser: [],
}

const requestInitialValues: OrderKpiRequest = {
  startDate: moment().add(-3, 'years').format('YYYY-MM-DD'),
  finalDate: moment().format('YYYY-MM-DD'),
  familyName: '',
  purchaserName: '',
  companyId: '',
  buildingId: '',
}

const getOrderKpi = (): OrderKpi => {
  if (!localStorage) {
    return initialValues
  }

  const lsValue: string | null = localStorage.getItem(KPI_LOCAL_STORAGE_KEY)

  if (!lsValue) {
    return initialValues
  }

  try {
    const orderKpi: OrderKpi = JSON.parse(lsValue) as OrderKpi
    if (orderKpi) {
      return orderKpi
    }
  } catch (error) {
    console.error('KPI LOCAL STORAGE PARSE ERROR', error)
  }

  return initialValues
}

const setOrderKpi = (orderKpi: OrderKpi | void) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(orderKpi)
    localStorage.setItem(KPI_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('KPI LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeOrderKpi = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(KPI_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('KPI LOCAL STORAGE REMOVE ERROR', error)
  }
}

function getChartOptions(height: number, dataX: Array<number>, dataY: Array<string>): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'Quantidade',
        data: dataX,
      },

      // {
      //   name: 'Revenue',
      //   data: dataX,
      // },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
    },
    xaxis: {
      categories: dataY,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          // const description = val > 1 ? 'reais' : 'real'
          const description = val > 1 ? 'pedidos' : 'pedido'
          return `${val} ${description}`
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export { getOrderKpi, setOrderKpi, removeOrderKpi, getChartOptions, initialValues, requestInitialValues }
