import {
  Actions,
  CURRENT_SERVICO_VIEW,
  CHANGE_SERVICO,
  CHANGE_COMPOSICAO,
  CHANGE_INSUMOS,
  ComposicaoInsumo,
  ServicoViewDTO,
  Servico,
  ServicoModel,
} from '../../index'

export const initialState: ServicoViewDTO = {
  servico: {} as Servico,
  composicao: {} as ComposicaoInsumo,
  insumos: [],
}

export const initialListState: ServicoModel = {
  name: '',
  macroGrupos: [],
}

export const reducer = (state: ServicoViewDTO = initialState, action: Actions): ServicoViewDTO => {
  switch (action.type) {
    case CURRENT_SERVICO_VIEW: {
      return {
        ...state,
        servico: action.payload.servico,
        composicao: action.payload.composicao,
        insumos: action.payload.insumos,
      }
    }
    case CHANGE_SERVICO: {
      return { ...state, servico: action.payload }
    }
    case CHANGE_COMPOSICAO: {
      return { ...state, composicao: action.payload }
    }
    case CHANGE_INSUMOS: {
      return { ...state, insumos: action.payload }
    }
    default:
      return { ...state }
  }
}
