export function currencyFormat(value: string | number): string {
  return Number(value).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
export function currencyToFloat(value: string): number {
  return parseFloat(value.replace(/[^\d.,]/g, '').replace(',', '.'))
}

export function toInt(value: string) {
  return isNaN(parseInt(value)) ? 0 : parseInt(value)
}
