import { FC } from 'react'

import { OrderKpiRequest, useOrderKpi } from '../../../modules/orderkpi'
import { Family } from '../../../modules/family'

interface ComboBoxFamilyProps {
  handler: (orderKpiRequest: OrderKpiRequest) => void
}
const ComboBoxFamily: FC<ComboBoxFamilyProps> = ({ handler }) => {
  const { kpiRequest, families } = useOrderKpi()

  const onChange = (event: any) => {
    handler({ ...kpiRequest, familyName: event.target.value })
  }

  // const onSearch = (value: string) => {
  //   families.filter(family => family.familyName.includes(value))
  // }

  return (
    <select
      className='form-select form-select-solid'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      placeholder='Família'
      onChange={onChange}
      // onClick={onSearch}>
    >
      {families.map(family => (
        <option key={family.familyId} value={family.familyName}>
          {family.familyName}
        </option>
      ))}
    </select>
  )
}

export { ComboBoxFamily }
