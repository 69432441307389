import axios from 'axios'
import { toast } from 'react-toastify'

import { Company } from '../index'

const API_URL = process.env.REACT_APP_API_URL_ADMINCORP_PEDIDO //ADICIONAR no .env.development
const API_COMPANIES_URL = `${API_URL}/v1/company/list`

export async function requestCompany() {
  return await axios
    .get<Array<Company>>(API_COMPANIES_URL)
    .then(result => {
      return result.data
    })
    .catch(error => {
      console.error(error)
      toast.error('Erro ao consultar o Company')
    })
}
